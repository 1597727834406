.single_content_sec {
	padding: rem-calc(50 0);
	position: relative;
	overflow: hidden;
	.single_content_list {
		padding: rem-calc(0 15);
		li {
			padding: rem-calc(15 0);
			margin-bottom: 0;
			&:before, &:after {
				display: none;
			}
			.bg_text {
				span {
					color: $white;
					text-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
				}
				&:before {
					height: auto;
					bottom: rem-calc(-70);
				}
			}
			.single-text-content {
				max-width: rem-calc(850);
				margin: rem-calc(-50) auto 0;
				text-align: center;
				position: relative;
				z-index: 1;
			}
		}
	}
	@include breakpoints(small) {
		padding: rem-calc(40 0 20);
		.single_content_list {
			li {
				.single-text-content {
					margin-top: rem-calc(-30);
				}
			}
		}
	}
	@include breakpoints(extra-small) {
		.single_content_list {
			li {
				.single-text-content {
					margin-top: 0;
				}
			}
		}
	}
}

.client_results_sec {
	font-family: $proxima_nova;
	padding: rem-calc(30 0 50);
	.client_result_left {
		position: relative;
		&:before {
			content: '';
			display: inline-block;
			border-right: rem-calc(1) solid $grey_border;
			width: rem-calc(1);
			position: absolute;
			top: rem-calc(20);
			right: 0;
			bottom: 0;
		}
	}
	.result-title {
		font-size: rem-calc(16);
		line-height: rem-calc(22);
		font-weight: $bold;
		letter-spacing: rem-calc(1);
		text-transform: uppercase;
		text-align: center;
	}
	.result_data {
		max-width: rem-calc(400);
		margin: 0 auto;
	}
	.result_col {
		padding: rem-calc(0 30 30);
		border-bottom: solid rem-calc(1) $grey_border;
		text-align: center;
		margin-bottom: rem-calc(50);
		.counter {
			color: $blue;
			font-size: rem-calc(72);
			line-height: rem-calc(80);
			font-weight: $bold;
			display: block;
			margin-bottom: rem-calc(15);
			sup {
				font-size: rem-calc(42);
				line-height: rem-calc(48);
				display: inline-block;
			}
		}
		p {
			color: $blue;
			font-size: rem-calc(24);
			line-height: rem-calc(36);
		}
		&:last-child {
			padding-bottom: 0;
			border-bottom: 0;
			margin-bottom: 0;
		}
	}
	@include breakpoints(tablet-landscape) {
		.result_col {
			.counter {
				font-size: rem-calc(60);
				line-height: rem-calc(68);
			}
		}
	}
	@include breakpoints(small) {
		.client_result_left {
			border-bottom: rem-calc(1) solid $grey_border;
			padding-bottom: rem-calc(20);
			margin-bottom: rem-calc(40);
			&:before {
				border-right: none;
			}
		}
	}
}

.main_row.case_study_single {
	.main_column {
		.full-width-row {
			.result_wrap {
				width: 100%;
			}
		}
	}
}

.graphWrap {
	height: rem-calc(320);
	position: relative;
	.graph {
		position: absolute;
		top: 100%;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 0;
		@include flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-end;
		@include transition(2s, all, ease);
		span {
			background-color: $blue_bg;
			width: rem-calc(110);
			display: block;
			margin: rem-calc(0 15);
			cursor: pointer;
			@include box-shadow(0, 0, 35px, 0, rgba(0, 0, 0, 0.35));
			@include transition(.5s, all, ease);
			&:first-child {
				background-color: $green_bg;
			}
			&:hover {
				opacity: 0.8;
			}
		}
		img {
			margin-right: rem-calc(25);
		}
	}
	@include breakpoints(small) {
		.graph {
			span {
				width: rem-calc(80);
				margin: rem-calc(0 10);
			}
		}
	}

}

.onView {
	.graphWrap {
		.graph {
			top: 0;
			height: 100%;
		}
	}
}




