.video_wrap{ 		
	background-color: $light_grey;
	padding: rem-calc(20 0 25);
	h2{
		color: $blue;
		margin-top: rem-calc(15);
		margin-bottom: rem-calc(38);
		text-align: center;
		padding: rem-calc(0 60);
		a{
			position: relative;
			&:before{
				content: '';
				display: inline-block;
				height: rem-calc(1);
				border-bottom: rem-calc(1) solid $blue;
				@include transition(.5s, all, ease);
				position: absolute;
				bottom: rem-calc(-3);
				left: 0;
				right: 0;
			}
			&:hover{
				&:before{
					right: 100%;
				}	
			}
		}
	}
	@include breakpoints(tablet-landscape){
		padding: rem-calc(20 0 40);	
		h2{
			padding: rem-calc(0 30);			
		}
	}
	@include breakpoints(small){
		h2{
			padding: 0;
		}
	}
}