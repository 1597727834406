
/*************************
Font Family
*/

$proxima_nova: 'proxima-nova', sans-serif;
$din: 'din-2014', sans-serif;


/* ----- Font weight ------- */
$light: 300;
$regular: 400;
$bold: 700;
$extrabold: 800;
$black: 900;

/* ----- Font style ------- */
$italic: italic;
$normal: normal;

/* ------------------ */

/* Color */
$grey_bg_text: #656569;
$grey_bg: #808080;
$grey_border: #e0e3e6;
$light_grey_bg: #ededed;
$primary_color: #96a3ad;
$transparent: transparent;
$white: #ffffff;
$black_color: #000000;
$blue: #293985;
$orange: #ff5001;
$dark_orange: #ff3333;
$light_grey: #eeeeee;
$grey: #65656a;
$med_grey: #97989a;
$dark_grey: #343e48;
$grey_border: #c9c7c7;
$green: #2f939d;
$grey_text: #b1b2b3;
$light_blue: #a9b0ce;
$white_light: #c8c6c7;
$blue_grey: #a5afb8;
$blue_bg: #0054a5;
$dark_blue: #111e5a;
$green_bg: #46ba7b;

$bd_color: #e0e0e1;
$bd_color_2: #c4c2c2;

.white{
	background-color: $white;
}
.greyBg{
	background-color: $light_grey;
}

/*************************
Mixins
*************************/

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
ul,
li {
	padding: 0;
	margin: 0;
	list-style: none;
}
.clearfix {
	display: table;
	width: 100%;
	content: '';
}

/************ Transition *************/

@mixin transition($element, $time, $transition-name) {
	-webkit-transition: $element $time $transition-name;
	-moz-transition: $element $time $transition-name;
	-ms-transition: $element $time $transition-name;
	-o-transition: $element $time $transition-name;
	transition: $element $time $transition-name;
}

/************ Box shadow *************/

@mixin box-shadow($x, $y, $spread, $size, $color, $inset: '') {
	-webkit-box-shadow: $x $y $spread $size $color #{$inset};
	-moz-box-shadow: $x $y $spread $size $color #{$inset};
	box-shadow: $x $y $spread $size $color #{$inset};
	-ms-box-shadow: $x $y $spread $size $color #{$inset};
	-o-box-shadow: $x $y $spread $size $color #{$inset};
}

/************ Tranform Skew *************/

@mixin transform-skewY($valYdeg) {
	-webkit-transform: skewY($valYdeg);
	-moz-transform: skewY($valYdeg);
	-ms-transform: skewY($valYdeg);
	-o-transform: skewY($valYdeg);
	transform: skewY($valYdeg);
}

/************ Tranform Skew *************/

@mixin transform-skew($val1deg,$val2deg) {
	-webkit-transform: skew($val1deg,$val2deg);
	-moz-transform: skew($val1deg,$val2deg);
	-ms-transform: skew($val1deg,$val2deg);
	-o-transform: skew($val1deg,$val2deg);
	transform: skew($val1deg,$val2deg);
}


/************ Tranform rotate *************/

@mixin transform-rotate($deg1) {
	-webkit-transform: rotate($deg1);
	-moz-transform: rotate($deg1);
	-ms-transform: rotate($deg1);
	-o-transform: rotate($deg1);
	transform: rotate($deg1);
}

/************ Tranform scale *************/

@mixin transform-scale($deg) {
	-webkit-transform: scale($deg);
	-moz-transform: scale($deg);
	-ms-transform: scale($deg);
	-o-transform: scale($deg);
	transform: scale($deg);
}

/************ Tranform Translate both *************/

@mixin transform-translate($valX,$valY) {
	-webkit-transform: translate($valX,$valY);
	-moz-transform: translate($valX,$valY);
	-ms-transform: translate($valX,$valY);
	-o-transform: translate($valX,$valY);
	transform: translate($valX,$valY);
}

/************ Tranform Translate Y *************/

@mixin transform-translate-ver($valY) {
	-webkit-transform: translateY($valY);
	-moz-transform: translateY($valY);
	-ms-transform: translateY($valY);
	-o-transform: translateY($valY);
	transform: translateY($valY);
}

/************ Tranform Translate X *************/

@mixin transform-translate-hor($valX) {
	-webkit-transform: translateX($valX);
	-moz-transform: translateX($valX);
	-ms-transform: translateX($valX);
	-o-transform: translateX($valX);
	transform: translateX($valX);
}

/************ Gradient *************/
@mixin linear-gradient($to, $from) {
	background: $to;
	background: -moz-linear-gradient(top, $from 0%, $to 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $from), color-stop(100%, $to));
	background: -webkit-linear-gradient(left, $from 0%, $to 100%);
	background: -o-linear-gradient(left, $from 0%, $to 100%);
	background: linear-gradient(to right, $from 0%, $to 100%);
	/*filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$from}, endColorstr=#{$to})*/
}


/************ Breakpoints *************/

@mixin breakpoints($breakpoint) {
	@if $breakpoint=="extremly-small" {
		@media(max-width: 450px) {
			@content;
		}
	}
	@if $breakpoint=="extra-small" {
		@media(max-width: 479px) {
			@content;
		}
	}
	@if $breakpoint=="x-small" {
		@media(max-width: 640px) {
			@content;
		}
	}
	@if $breakpoint=="small" {
		@media(max-width: 767px) {
			@content;
		}
	}
	@if $breakpoint=="min-tablet" {
		@media(min-width: 767px) {
			@content;
		}
	}
	@if $breakpoint=="tablet" {
		@media(max-width: 768px) {
			@content;
		}
	}
	@if $breakpoint=="tablet-medium" {
		@media(max-width: 850px) {
			@content;
		}
	}
	@if $breakpoint=="tablet-portrait" {
		@media(max-width: 991px) {
			@content;
		}
	}
	@if $breakpoint=="tablet-landscape" {
		@media(max-width: 1023px) {
			@content;
		}
	}
	@if $breakpoint=="tablet-landscape-x" {
		@media(max-width: 1080px) {
			@content;
		}
	}
	@if $breakpoint=="extremly-large" {
		@media(max-width: 1100px) {
			@content;
		}
	}
	@if $breakpoint=="large" {
		@media(max-width: 1200px) {
			@content;
		}
	}
	@if $breakpoint=="x-large" {
		@media(max-width: 1300px) {
			@content;
		}
	}
	@if $breakpoint=="max-x-large" {
		@media(max-width: 1310px) {
			@content;
		}
	}
	@if $breakpoint=="max-xx-large" {
		@media(max-width: 1366px) {
			@content;
		}
	}
	@if $breakpoint=="extra-large" {
		@media(max-width: 1400px) {
			@content;
		}
	}
	@if $breakpoint=="extra-large-x" {
		@media(max-width: 1650px) {
			@content;
		}
	}
	@if $breakpoint=="min-x-large" {
		@media(min-width: 1366px) {
			@content;
		}
	}
	@if $breakpoint=="xxlarge" {
		@media(min-width: 1440px) {
			@content; 
		}
	}
	@if $breakpoint=="extra-large-xx" {
		@media(min-width: 1920px) {
			@content;
		}
	}	
	@if $breakpoint=="double-extra-large" {
		@media(min-width: 2000px) {
			@content;
		}
	}
	@if $breakpoint=="double-extra-x-large" {
		@media(min-width: 2540px) {
			@content;
		}
	}
	
}

.button {
	background-color: $blue;
	font-family: $proxima_nova;
	font-weight: $black;
	font-size: rem-calc(22);
	color: $white;
	line-height: rem-calc(30);
	letter-spacing: rem-calc(0.5);
	display: inline-block;
	border: rem-calc(1) solid $blue;
	border-radius: rem-calc(30);
	padding: rem-calc(15 30);
	min-width: rem-calc(184);
	text-decoration: none;
	@include transition(.5s, all, ease);
	@include box-shadow(0, 0, 35px, 0, rgba(0,0,0,0.3));
	.arrow-right{
		display: inline-block;
		width: 0;
		height: 0;
		border-top: rem-calc(4) solid transparent;
		border-bottom: rem-calc(4) solid transparent;
		border-left: rem-calc(5) solid $white;
		margin-left: rem-calc(8);
		vertical-align: middle;
		margin-top: rem-calc(-3);
	}
	.arrow-up{
		display: inline-block;
		width: 0;
		height: 0;
		margin-left: rem-calc(3);
		vertical-align: middle;
		border-left: rem-calc(5) solid transparent;
		border-right: rem-calc(5) solid transparent;
		border-bottom: rem-calc(4) solid $blue;
		vertical-align: middle;
	}
	.arrow-down{
		display: inline-block;
		width: 0;
		height: 0;
		margin-left: rem-calc(3);
		vertical-align: middle;
		border-left: rem-calc(5) solid transparent;
		border-right: rem-calc(5) solid transparent;
		border-top: rem-calc(4) solid $blue;
		vertical-align: middle;
	}
	&:hover, &:focus{
		background-color: $white;
		color: $blue;
		border-color: $blue;
		.arrow-right{
			border-left-color: $blue;
		}
	}
	&.lg-button{
		padding: rem-calc(15 30);
	}
	&.sm-button{
		font-size: rem-calc(16);
	}
	&.orange-button{
		background-color: $white; 
		color: $orange;
		border-color: $orange;
		.arrow-right{
			border-top: rem-calc(7) solid transparent;
			border-bottom: rem-calc(7) solid transparent;
			border-left: rem-calc(7) solid $orange; 
		}
		&:hover, &:focus{
			background-color: $orange;
			color: $white; 
			border-color: $orange;  
			.arrow-right{
				border-left-color: $white;
			}
		}
	}
	&.white-button{
		background-color: transparent;
		color: $blue;
		border-color: $blue;
		.arrow-right{
			border-left-color: $blue;
		}
		&:hover, &:focus{
			background-color: $blue;
			color: $white;
			.arrow-right{
				border-left-color: $white;
			}
		}
	}
	@include breakpoints(small){
		font-size: rem-calc(16);
		line-height: rem-calc(22);
		padding: rem-calc(10 15);
	}
}
a {
	text-decoration: none;
	@include transition(.5s, all, ease);
	color: $blue;
	&:hover, &:focus{
		color: $dark_blue;
	}
}
.cf:before,
.cf:after {
	display: table;
	content:'';
}
.cf:after {
	clear: both;
}
.cf {
	*zoom: 1;
}
p {
	font-family: $proxima_nova;
	font-weight: $regular;
	color: $primary_color;
	font-size: rem-calc(20);
	line-height: rem-calc(30);
	margin: rem-calc(0 0 20);
	@include breakpoints(small){
		font-size: rem-calc(18);
		line-height: rem-calc(24);
	}
}
ul{
	margin: rem-calc(0 0 10);
	li{
		font-family: $proxima_nova;
		font-weight: $regular;
		color: $primary_color;
		font-size: rem-calc(20);
		line-height: rem-calc(30);
		padding-left: rem-calc(66);
		position: relative;
		margin-bottom: rem-calc(20);
		&:before{
			content: '';
			display: inline-block;
			background-color: transparent;
			width: rem-calc(38);
			height: rem-calc(38);
			border: solid rem-calc(1) $light_grey;
			border-radius: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
		&:after{
			content: '';
			display: inline-block;
			width: rem-calc(10);
			height: rem-calc(6);
			background-color: transparent;
			border: solid rem-calc(2) $blue;
			border-width: rem-calc(0 0 2 2);
			position: absolute;
			top: rem-calc(19);
			left: rem-calc(19);
			margin-top: rem-calc(-3);
			margin-left: rem-calc(-5);
			@include transform-rotate(-45deg);
		}
		a{
			text-decoration: underline;
		}
		@include breakpoints(small){
			font-size: rem-calc(18);
			line-height: rem-calc(24);
			padding-left: rem-calc(50);
			margin-bottom: rem-calc(18);
			&:before{
				width: rem-calc(32);
				height: rem-calc(32);
			}
			&:after{
				top: rem-calc(15);
				left: rem-calc(16);
				margin-top: rem-calc(-3);
				margin-left: rem-calc(-5);
			}
		}
	}
}
ol{
	margin: rem-calc(0 0 10);
	padding-left: rem-calc(30);
	li{
		font-family: $proxima_nova;
		font-weight: $regular;
		font-size: rem-calc(20);
		line-height: rem-calc(30);
		list-style-type: decimal;
		@include breakpoints(small){
			font-size: rem-calc(18);
			line-height: rem-calc(24);
		}
	}
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $proxima_nova;
	font-weight: $regular;
	margin: 0;
}
h1,
.h1 {
	color: $dark_grey;
	font-size: rem-calc(36);
	line-height: rem-calc(48);
	font-weight: $bold;
	margin-bottom: rem-calc(20);
	@include breakpoints(small){
		font-size: rem-calc(30);
		line-height: rem-calc(36);
	}
}
h2,
.h2 {
	color: $dark_grey;
	font-size: rem-calc(30);
	line-height: rem-calc(36);
	font-weight: $bold;
	margin-bottom: rem-calc(20);
	@include breakpoints(small){
		font-size: rem-calc(24);
		line-height: rem-calc(30);
	}
}
h3,
.h3 {
	color: $dark_grey;
	font-size: rem-calc(30);
	line-height: rem-calc(36);
	margin-bottom: rem-calc(20);
	@include breakpoints(small){
		font-size: rem-calc(24);
		line-height: rem-calc(34);
	}
}
h4,
.h4 {
	color: $primary_color;
	font-size: rem-calc(19);
	line-height: rem-calc(29);
}
h5,
.h5 {
	color: $primary_color;
	font-size: rem-calc(16);
	line-height: rem-calc(26);
}
h6,
.h6 {
	color: $primary_color;
	font-size: rem-calc(12);
	line-height: rem-calc(22);
}
.center-align {
	text-align: center;
}
.left-align {
	text-align: left;
}
.right-align {
	text-align: right;
}
.bottom-align {
	align-items: flex-start;
}

input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	opacity: 1;
	color: $grey_bg_text;
}
input::-moz-placeholder { /* Firefox 19+ */
	opacity: 1;
	color: $grey_bg_text;
}
input:-ms-input-placeholder { /* IE 10+ */
	opacity: 1;
	color: $grey_bg_text;
}
input:-moz-placeholder { /* Firefox 18- */
	opacity: 1;
	color: $grey_bg_text;
}

textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	opacity: 1;
	color: $grey_bg_text;
}
textarea::-moz-placeholder { /* Firefox 19+ */
	opacity: 1;
	color: $grey_bg_text;
}
textarea:-ms-input-placeholder { /* IE 10+ */
	opacity: 1;
	color: $grey_bg_text;
}
textarea:-moz-placeholder { /* Firefox 18- */
	opacity: 1;
	color: $grey_bg_text;
}
.white_wrap {
	color: $white;
	h1, h2, h3, h4, h5, h6{
		color: $white;
	}
	p{
		color: $white;
		a{
			&:hover{
				text-decoration: underline;
			}
		}
	}
	a{
		color: $white;
		text-decoration: none;
	}
}
.intro-text{
	text-align: center;
	max-width: rem-calc(800);
	margin: 0 auto;
}
.bgimage{
	background-size: cover !important;
	background-position: center center !important;
	background-repeat: no-repeat !important;
}

.animation_fadeIn .animated,
.animation_fadeInUp .animated{
	visibility: hidden;
}
.animation_fadeIn .animated.fadeIn,
.animation_fadeInUp .animated.fadeInUp {
	visibility: visible;
}

hr{
	max-width: none;
	border-bottom-color: $light_gray;
}

.bg_text {
	font-family: $proxima_nova;
	width: 100%;
	height: rem-calc(172);
	text-align: center;
	display: block;
	position: relative;
	span{
		color: $light_grey;
		font-size: rem-calc(246);
		line-height: rem-calc(172);
		font-weight: $black;
		text-transform: uppercase;
		display: block;
		position: absolute;
		bottom: 0;
		left: 50%;
		@include transform-translate-hor(-50%);
		white-space: nowrap;
	}
	&:before{
		content: '';
		display: inline-block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		/* ms ( IE 10.0+ ) */
		background:-ms-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.85) 100% );

		/* WebKit (Chrome 10.0+, safari 5.1+ )*/
		background:-webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.85) 100% );

		/* Moz ( Moz 3.6+ )*/
		background:-moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.85) 100% );

		/* Opera ( opera 11.6+ )*/
		background:-o-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.85) 100% );

		/* W3C Markup */
		background:linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.85) 100% );
	}
	&.blue{
		text-align: center;
		span{
			color: $blue;
			text-shadow: 0 0 35px rgba(0,0,0,0.25);
		}
		&:before{
			/* ms ( IE 10.0+ ) */
			background:-ms-linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(41,57,133,1)  );

			/* WebKit (Chrome 10.0+, safari 5.1+ )*/
			background:-webkit-linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(41,57,133,1) 100% );

			/* Moz ( Moz 3.6+ )*/
			background:-moz-linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(41,57,133,1) 100% );

			/* Opera ( opera 11.6+ )*/
			background:-o-linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(41,57,133,1) 100% );

			/* W3C Markup */
			background:linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(41,57,133,1) 100% );
		}
	}
	&.grey{
		span{
			color: $white;
		}
		&:before{
			/* ms ( IE 10.0+ ) */
			background:-ms-linear-gradient(180deg, rgba(255,255,255,0) 50%, rgba(238,238,238,0.85) 100% );

			/* WebKit (Chrome 10.0+, safari 5.1+ )*/
			background:-webkit-linear-gradient(180deg, rgba(255,255,255,0) 50%, rgba(238,238,238,0.85) 100% );

			/* Moz ( Moz 3.6+ )*/
			background:-moz-linear-gradient(180deg, rgba(255,255,255,0) 50%, rgba(238,238,238,0.85) 100% );

			/* Opera ( opera 11.6+ )*/
			background:-o-linear-gradient(180deg, rgba(255,255,255,0) 50%, rgba(238,238,238,0.85) 100% );

			/* W3C Markup */
			background:linear-gradient(180deg, rgba(255,255,255,0) 50%, rgba(238,238,238,0.85) 100% );
		}
	}
	@include breakpoints(large){
		height: rem-calc(120);
		span{
			font-size: rem-calc(180);
			line-height: rem-calc(120);
		}
	}
	@include breakpoints(tablet-landscape){
		height: rem-calc(120);
		span{
			font-size: rem-calc(140);
			line-height: rem-calc(120);
		}
	}
	@include breakpoints(small){
		height: rem-calc(80);
		/*display: none;*/
		span{
			font-size: rem-calc(100);
			line-height: rem-calc(80);
            display: none;
		}
        .button{
            margin: rem-calc(0 0 0 0);
        }
	}
	@include breakpoints(x-small){
		height: rem-calc(60);
		span{
			font-size: rem-calc(80);
			line-height: rem-calc(60);
		}
	}
	@include breakpoints(extra-small){
		height: rem-calc(40);
		span{
			font-size: rem-calc(60);
			line-height: rem-calc(40);
		}
	}
}
iframe{
	border: none;
}
.page_not_found {
	.main-grid {
		margin-bottom: rem-calc(30);
		width: 100%;
		.main-content {
			width: 100%;
			margin: 0;
		}
	}
}


