body{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-text-size-adjust: none;
    background-color: $white;
	padding: rem-calc(92 0 0);
	position: relative;
    /*.body_inner{
        padding: rem-calc(92 0 0);
        background-color: $white;
        max-width: rem-calc(1680);
        margin: rem-calc(0 auto);
        position: relative;
        overflow: hidden;
        &.inner-width{
            padding: rem-calc(0 0 0);
        }
    }*/
    .menu-open{
        overflow-y: hidden;
    }
    @include breakpoints(tablet-portrait){
        padding: rem-calc(60 0 0);
    }
}

.grid-container{
    padding: rem-calc(0 15);
}

.main-grid .main-content {
    margin: 0 auto;
}



.loader {
    font-family: $proxima_nova;
    background-repeat: no-repeat;
    left: 0;
    right: 0;
    position: fixed;
    margin: 0 auto;
    background: rgba(255, 80, 1, 0.8);
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    z-index: 999;
}

.loader-inner {
    font-family: $proxima_nova;
    font-size: rem-calc(50);
    color: $white;
    font-weight: $black;
    text-transform: uppercase;
    letter-spacing: rem-calc(10);
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    white-space: nowrap;
    @include transition(.5s, all, ease);
    @include breakpoints(small){
        font-size: rem-calc(24);
        letter-spacing: rem-calc(5);
    }
}

.loader-inner::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: $blue;
    overflow: hidden;
    animation: animate-loader 7s linear infinite;
    border-right: rem-calc(4) solid $blue;
    white-space: nowrap;
}

@keyframes animate-loader{
    0%{
        width: 0;
    }
    50%{
        width: 99%;
    }
    100%{
        width: 0;
    }
}
@-webkit-keyframes animate-loader{
    0%{
        width: 0;
    }
    50%{
        width: 99%;
    }
    100%{
        width: 0;
    }
}

/* Pagination */
.more-items{
    padding: rem-calc(40 0);
    text-align: center;
    margin-bottom: 0;
    a{
        min-width: rem-calc(184);
        margin-bottom: 0;
    }
    @include breakpoints(small){
        padding: rem-calc(30 0);
        a{
            min-width: inherit;
        }
    }
}

.pagination{
    &.bottom-pagination{
        display: flex;
        flex-wrap: wrap;
        padding: rem-calc(40 0);
        margin-bottom: 0;
        .next-page{
            margin-right: auto;
            a{
                .arrow-right{
                    border-left: 0;
                    border-right: rem-calc(5) solid $blue;
                }
            }
        }
        .previous-page{
            margin-left: auto;
        }
        a{
            background-color: transparent;
            font-family: $proxima_nova;
            font-weight: $black;
            font-size: rem-calc(16);
            color: $blue;
            line-height: rem-calc(22);
            letter-spacing: rem-calc(0.5);
            display: inline-block;
            border: rem-calc(1) solid $blue;
            border-radius: rem-calc(30);
            padding: rem-calc(18 15);
            min-width: rem-calc(184);
            text-decoration: none;
            text-align: center;
            @include transition(.5s, all, ease);
            text-transform: capitalize;
            .arrow-right{
                display: inline-block;
                width: 0;
                height: 0;
                border-top: rem-calc(4) solid transparent;
                border-bottom: rem-calc(4) solid transparent;
                border-left: rem-calc(5) solid $blue;
                margin-left: rem-calc(3);
                vertical-align: middle;
            }
            &:hover, &:focus{
                background-color: $blue;
                color: $white;
                .arrow-right{
                    border-left-color: $white;
                    border-right-color: $white;
                }
            }
        }
        @include breakpoints(small){
            padding: rem-calc(30 0);
            a{
                padding: rem-calc(12 15);
                min-width: inherit;
            }
        }
        @include breakpoints(x-small){
            .next-page{
                order: 1;
                width: 48%;
                padding-right: rem-calc(10);
                a{
                    display: block;
                }
            }
            .previous-page{
                order: 2;
                width: 48%;
                padding-left: rem-calc(10);
                a{
                    display: block;
                }
            }
            .all-page{
                order: 3;
                width: 100%;
                padding-top: rem-calc(20);
                a{
                    display: block;
                }
            }
        }
    }
}

.pageLink{
    background-color: $white;
    width: rem-calc(45);
    height: rem-calc(45);
    line-height: rem-calc(45);
    vertical-align: middle;
    text-align: center;
    border-radius: 100%;
    position: absolute;
    bottom: rem-calc(30);
    @include transform-translate-ver(-50%);
    font-size: 0;
    background-repeat: no-repeat;
    background-position: center center;
    &.nextLink{
        left: 0;
        background-image: url(../images/prev-arrow.svg);
        background-size: 11px;
    }
    &.previousLink{
        right: 0;
        background-image: url(../images/next-arrow.svg);
        background-size: 11px;
    }
    &:hover{
        opacity: 0.85;
    }
    a{
        &:hover{
            background: none;
            background-color: transparent;
        }
    }
    @include breakpoints(small){
        width: rem-calc(38);
        height: rem-calc(38);
        &.nextLink{
            left: rem-calc(15);
            background-size: 9px;
        }
        &.previousLink{
            right: rem-calc(15);
            background-size: 9px;
        }
    }
}

.open_content_sec{
    padding: rem-calc(50 0);
    @include breakpoints(small){
        padding: rem-calc(40 0);
    }
}


/* Extra CSS */
#resumator-wrapper{
    padding: 30px 0 !important;
}
.resumator-job-title {
    border-top: none !important;
    font-size: 24px !important;
    color: #343e48 !important;
}
.resumator-jobs-text {
    font-size: 14px !important;
    color: #343e48 !important;
}
.resumator-job-description ul, .resumator-job-description ol {
    margin: 0;
    li {
        &:before, &:after{
            display: none;
        }
    }
}
.resumator-job-description li, .resumator-job-description p{
    font-family: $proxima_nova !important;
    font-weight: $regular !important;
    color: $primary_color !important;
    font-size: rem-calc(20) !important;
    line-height: rem-calc(30) !important;
    @include breakpoints(small){
        font-size: rem-calc(18)!important;
        line-height: rem-calc(24)!important;
    }
}

.resumator-buttons ul li input[type=button], .resumator-buttons ul li a[type=button] {
    background-color: $blue;
    font-family: $proxima_nova;
    font-weight: $black;
    font-size: rem-calc(22);
    color: $white;
    line-height: rem-calc(30);
    letter-spacing: rem-calc(0.5);
    display: inline-block;
    border: rem-calc(1) solid $blue;
    border-radius: rem-calc(30) !important;
    padding: rem-calc(15 30);
    min-width: rem-calc(184);
    text-decoration: none;
    @include transition(.5s, all, ease);
    @include box-shadow(0, 0, 35px, 0, rgba(0,0,0,0.3));
    margin: rem-calc(0 15 15 0);
    cursor: pointer;
    text-align: center;
    &:hover, &:focus{
        background-color: $white;
        color: $blue;
        border-color: $blue;
        .arrow-right{
            border-left-color: $blue;
        }
    }
    @include breakpoints(small){
        font-size: rem-calc(16);
        line-height: rem-calc(22);
        padding: rem-calc(10 15);
    }
}


/* Loader */
.loader_img { text-align: center; /*position: fixed; top: 0; right: 0; bottom: 0; left: 0;*/  
	background-color: rgba(255,255,255,0.8); z-index: 3; text-transform: uppercase; letter-spacing: 1px; color: #343e48; 
	font-family: $proxima_nova; font-weight: $bold; padding: 50px 0;
}
/*.loader_img:before {    content: ''; display: inline-block; background-color: rgba(255,255,255,0.8); position: absolute; top: 0; left: 0; width: 100%;
    height: 100%;}*/
.loader_img > div {/*position: fixed;top: 50%;left: 50%;-webkit-transform: translate(-50%,-50%);-moz-transform: translate(-50%,-50%);transform: translate(-50%,-50%);*/}
.loader_img .loadImg {  display: block;  width: 100px; height: 100px;
background-image: url('../images/rolling-1.png'); background-position: center; background-repeat: no-repeat; background-size: 100%;
animation-name: spin;  animation-duration: 5000ms; animation-iteration-count: infinite; animation-timing-function: linear; margin: 0 auto 10px; 
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}







