.case_studies_slider {
	padding: rem-calc(60 0 0);
	.intro-text {
		text-align: center;
		margin-bottom: rem-calc(50);
	}	
	@include breakpoints(small) {
		.owl-dots {
			bottom: rem-calc(10);
		}
		.owl-nav {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			text-align: center;
			height: rem-calc(70);
			padding: rem-calc(15 0);
			div {
				position: relative;
				left: auto;
				right: auto;
				top: auto;
				display: inline-block;
				transform: inherit;
				bottom: auto;
				margin: rem-calc(0 10);
				&.owl-prev {
					left: auto;
				}
				&.owl-next {
					right: auto;
				}
			}
		}
	}
}

.owl-nav {
	div {
		background-color: $white;
		width: rem-calc(45);
		height: rem-calc(45);
		line-height: rem-calc(45);
		vertical-align: middle;
		text-align: center;
		border-radius: 100%;
		position: absolute;
		top: 50%;
		@include transform-translate-ver(-50%);
		font-size: 0;
		background-repeat: no-repeat;
		background-position: center center;
		&.owl-prev {
			left: rem-calc(60);
			background-image: url("../images/prev-arrow.svg");
			background-size: 11px;
		}
		&.owl-next {
			right: rem-calc(60);
			background-image: url("../images/next-arrow.svg");
			background-size: 11px;
		}
	}
	@include breakpoints(small) {
		div {
			width: rem-calc(38);
			height: rem-calc(38);
			&.owl-prev {
				left: rem-calc(15);
				background-size: 9px;
			}
			&.owl-next {
				right: rem-calc(15);
				background-size: 9px;
			}
		}
	}
}

.owl-dots {
	width: 100%;
	text-align: center;
	position: absolute;
	bottom: rem-calc(30);
	.owl-dot {
		background-color: $white;
		width: rem-calc(8);
		height: rem-calc(8);
		border: solid rem-calc(1) $blue;
		border-radius: 100%;
		display: inline-block;
		margin: rem-calc(0 8);
		&.active {
			background-color: $blue;
			border-color: $white;
		}
	}
}

.main_row {
	background-color: $blue;
	font-family: $proxima_nova;
	position: relative;
	margin-bottom: rem-calc(10);
	.featured_img_column {
		width: 50%;
		@extend .bgimage;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
	}
	.main_column {
		position: relative;
		z-index: 1;
		padding: rem-calc(55 0 80);
		.text-content {
			max-width: 50%;
			float: right;
			padding: rem-calc(0 80 0 120);
			margin-bottom: rem-calc(45);
		}
		.title-logo {
			display: block;
			margin-bottom: rem-calc(44);
			max-width: rem-calc(400);
			img {
				width: auto;
			}
		}
		p {
			color: $white;
			font-size: rem-calc(18);
			line-height: rem-calc(30);
			letter-spacing: rem-calc(-0.4);
		}
		.full-width-row {
			width: 100%;
			@include flex;
			@include flex-align(center, flex-end);
			flex-wrap: wrap;
			.button-grp {
				width: 32%;
				text-align: center;
				.button {
					min-width: rem-calc(260);
					padding-left: rem-calc(20);
					padding-right: rem-calc(20);
				}
			}
			.result_wrap {
				background-color: $light_grey;
				padding: rem-calc(25 0 0);
				text-align: center;
				width: 68%;
				@include box-shadow(0, 0, 50px, 0, rgba(0, 0, 0, 0.35));
				.result-title {
					font-size: rem-calc(16);
					font-family: $proxima_nova;
					letter-spacing: rem-calc(1.5);
					text-transform: uppercase;
					color: $med_grey;
					font-weight: $bold;
					display: block;
					margin-bottom: rem-calc(15);
				}
				.result_inner_row {
					@include flex;
					flex-wrap: wrap;
					justify-content: center;
					.result_col {
						width: 50%;
						min-height: rem-calc(210);
						padding: rem-calc(0 30 15);
						border-right: solid rem-calc(1) $grey_border;
						p {
							color: $blue;
							font-size: rem-calc(24);
							line-height: rem-calc(36);
						}
						.counter {
							color: $blue;
							font-size: rem-calc(72);
							line-height: rem-calc(80);
							font-weight: $bold;
							display: block;
						}
					}
				}
			}
		}
	}

	&.right {
		.featured_img_column {
			left: auto;
			right: 0;
		}
		.main_column {
			.text-content {
				float: left;
				padding: rem-calc(0 80);
			}
			.full-width-row {
				.button-grp {
					order: 2;
				}
			}
		}
	}
	&.case_study_single {
		margin-bottom: rem-calc(120);
		.main_column {
			padding-bottom: 0;
			.full-width-row {
				.result_wrap {
					max-width: rem-calc(820);
					margin-bottom: rem-calc(-120);
				}
			}
		}
	}

	@include breakpoints(large) {
		.main_column {
			.text-content {
				padding: rem-calc(0 30);
				margin-bottom: rem-calc(30);
			}
			.full-width-row {
				.result_wrap {
					.result_inner_row {
						.result_col {
							.counter {
								font-size: rem-calc(60);
								line-height: rem-calc(68);
							}
						}
					}
				}
			}
		}
		&.right {
			.main_column {
				.text-content {
					padding: rem-calc(0 30);
				}
			}
		}
	}
	@include breakpoints(tablet-portrait) {
		.main_column {
			.title-logo {
				max-width: rem-calc(300);
			}
			.full-width-row {
				.button-grp {
					width: 38%;
				}
				.result_wrap {
					width: 62%;
					.result_inner_row {
						.result_col {
							padding: rem-calc(0 15 15);
							min-height: rem-calc(160);
							p {
								font-size: rem-calc(20);
								line-height: rem-calc(28);
							}
							.counter {
								font-size: rem-calc(42);
								line-height: rem-calc(50);
							}
						}
					}
				}
			}
		}
	}
	@include breakpoints(small) {
		.featured_img_column {
			width: 100%;
			position: inherit;
			right: auto;
			left: auto;
			padding-bottom: 54.5%;
		}
		.main_column {
			padding: rem-calc(40 0 70);
			text-align: center;
			.text-content {
				padding: 0;
				float: none !important;
				.title-logo {
					margin-left: auto;
					margin-right: auto;
					img {
						display: inline-block;
					}
				}
			}
			.full-width-row {
				.button-grp {
					width: 40%;
					.button{
						font-size: rem-calc(14);
						min-width: rem-calc(180);
					}
				}
				.result_wrap {
					width: 60%;
					.result_inner_row {
						.result_col {
							min-height: rem-calc(120);
							p {
								font-size: rem-calc(18);
								line-height: rem-calc(24);
							}
							.counter {
								font-size: rem-calc(32);
								line-height: rem-calc(38);
							}
						}
					}
				}
				.button {
					background-color: transparent;
					border-color: $white;
					color: $white;
					&:hover, &:focus {
						background-color: $white;
						color: $blue;
					}
				}
			}
			.text-content {
				max-width: inherit;
				float: none;
				padding: 0;
				margin-bottom: rem-calc(30);
			}
		}
		&.right {
			.main_column {
				.text-content {
					padding: 0;
				}
			}
		}
	}
	@include breakpoints(x-small) {
		.main_column {
			.text-content {
				margin-bottom: rem-calc(20);
			}
			.full-width-row {
				.result_wrap {
					width: 100%;
				}
				.button-grp {
					width: 100%;
					margin-bottom: rem-calc(15);
					.button {
						margin: rem-calc(0 15 15 0);
					}
					br {
						display: none;
					}
				}
			}
		}
		&.right {
			.main_column {
				.full-width-row {
					.button-grp {
						order: inherit;
					}
				}
			}
		}
	}
	@include breakpoints(extra-small) {
		.main_column {
			.full-width-row {
				padding: rem-calc(15 0 0);
				.result_wrap {
					.result_inner_row {
						display: block;
						.result_col {
							width: 100%;
							min-height: auto;
							border-right: 0;
							border-bottom: solid rem-calc(1) $grey_border;
							padding: rem-calc(15);
							p {
								margin-bottom: 0;
							}
							&:last-child {
								border-bottom: none;
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}


.reveal.videoModal {
  padding: 2em 0;
  background: transparent;
  border: 0;
  outline: none;
}

.reveal.videoModal .close-button {
  position: absolute;
  top: -0.2em;
  color: rgb(255,255,255);
  text-shadow: 2px 2px 8px rgb(0,0,0);
  display: block;
  outline: none;
	transition: none;
  :hover {
    color: rgb(142, 216, 248);
    text-shadow: 2px 2px 5px rgb(0,0,0);
  }
}

.st_goodfltr{
	position: relative;
	text-align: center;
	height: 0;
	select { 
		background-image: url("../../../src/assets/images/select-down-arrow.png");
		height: rem-calc(34);
		font-family: $proxima_nova;
		font-weight: $black;
		font-size: rem-calc(13);
		text-transform: capitalize;
		position: relative;
		top: rem-calc(-18);		
		z-index: 2;
		text-align: center;
		background-color: $white;
		color: $orange;
		width:auto;
		min-width: rem-calc(158);
		margin: 0 auto;
		border-radius: rem-calc(15);
		border:none;
		padding: rem-calc(0 40 0 23);
	}
}

.page_number { 
	ul{
		padding: rem-calc(10 0);
		li{
			display: inline-block;
			padding-left: 0;
			margin: rem-calc(10 1);
			&:before, &:after{
				display: none;
			}
			a{
				background-color: $light_grey;
				padding: rem-calc(10 20);
				text-decoration: none;
				&:hover, &:focus {
					background-color: $blue;
					color: $white;
				}
				@include breakpoints(small){
					padding: rem-calc(6 12);
				}
			}
		}
	}
}






