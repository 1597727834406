.google_partner_sec {
	padding: rem-calc(75 0);
	.google_partner_inner {
		text-align: center;
		max-width: rem-calc(800);
		margin: 0 auto;
		p {
			font-size: rem-calc(20);
			line-height: rem-calc(30);
		}
		.google-partners-logos {
			margin-top: rem-calc(75);
			display: flex;
			justify-content: space-around;
			align-items: center;
			li {
				padding: 0;
				margin: rem-calc(0 15);
				display: inline-block;
				&:before, &:after {
					display: none;
				}
			}
		}
	}
	@include breakpoints(tablet-portrait) {
		padding: rem-calc(60 0);
		.google_partner_inner {
			.google-partners-logos {
				margin-top: rem-calc(50);
			}
		}
	}
	@include breakpoints(small) {
		padding: rem-calc(40 0);
		.google_partner_inner {
			p {
				font-size: rem-calc(18);
				line-height: rem-calc(28);
			}
			.google-partners-logos {
				margin-top: rem-calc(40);
			}
		}
	}
	@include breakpoints(x-small) {
		.google_partner_inner {
			.google-partners-logos {
				display: block;
				margin-top: rem-calc(30);
				margin-bottom: 0;
				li {
					display: block;
					width: 100%;
					padding: rem-calc(15 0);
					margin: 0;
				}
			}
		}
	}
}

