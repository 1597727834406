.profit_calculator_sec{ 	
	font-family: $proxima_nova;
	background-color: $blue;
	padding: rem-calc(50 0 285);
	.intro-text{
		h2{
			color: $white;
		}
	}
	.profit_calculator_inner{
		background-color: $light_grey;
		max-width: rem-calc(1092);	
		margin: 0 auto;
		padding: rem-calc(50 0 0);
		text-align: center;
		.small-text{
			font-size: rem-calc(16);
			line-height: rem-calc(28);			
			color: $med_grey;
			font-weight: $bold;
			text-transform: uppercase;
			letter-spacing: rem-calc(2);
		}
		.business-data-col{
			padding: rem-calc(0 15 50);
			border-right: rem-calc(1) solid $bd_color_2;				
			height: 100%;
			text-align: center;
		}
		.business-data{
			li{
				color: $blue;
				padding: 0;
				margin-bottom: rem-calc(10);
				&:before, &:after{
					display: none;
				}
				.business_title{
					margin-bottom: rem-calc(10);
					position: relative;
					display: inline-block;
					max-width: rem-calc(220);
				}
				.tooltip-wrap{
					background-color: $white;
					color: $blue;
					font-style: italic;
					width: rem-calc(26);
					height: rem-calc(26);
					border-radius: 100%;
					line-height: rem-calc(26);
					vertical-align: bottom;
					margin-left: rem-calc(20);
					display: inline-block;
					position: absolute;
					left: 100%;
					top: 0;					
					&:hover, &:focus{
						background-color: $blue;
						color: $white;
					}		
					.tooltip-icon{
						display: block;
						cursor: pointer;
					}					
					.price-text{
						font-size: rem-calc(36);
						line-height: rem-calc(42);
						font-weight: $bold;
						background-color: $white;					
						display: inline-block;
						min-width: rem-calc(217);
						padding: rem-calc(6 20);
						text-align: center;
						.left_sign{
							font-size: rem-calc(27);
							float: left;
							display: inline-block;
						}
						.right_sign{
							font-size: rem-calc(27);
							float: right;
							display: inline-block;
						}
						@include breakpoints(small){
							font-size: rem-calc(24);
							line-height: rem-calc(30);
						}
					}
				}			
			}
		}
		.results-data-col{
			height: 100%;
			padding: rem-calc(0 15 0);
			text-align: center;
			ul{
				li{
					color: $blue;
					padding: 0;
					margin-bottom: rem-calc(30);
					&:before, &:after{
						display: none;
					}
					strong{
						font-size: rem-calc(36);
						line-height: rem-calc(42);
						font-weight: $bold;
						display: block;
						@include breakpoints(small){
							font-size: rem-calc(24);
							line-height: rem-calc(30);
						}
					}
					@include breakpoints(small){
						margin-bottom: rem-calc(20);
					}
				}
			}
			.result-box{
				width: 100%;
				background-color: $white;				
				padding: rem-calc(40 15);
				@include box-shadow(0, 0, 35px, 0, rgba(0,0,0,0.35));
				margin-bottom: rem-calc(-204);
				p{
					font-size: rem-calc(24);
					line-height: rem-calc(30);					
					color: $blue;
					@include breakpoints(small){
						font-size: rem-calc(18);
						line-height: rem-calc(24);	
					}
				}
				.big-text{
					font-size: rem-calc(72);
					line-height: rem-calc(80);
					font-weight: $bold;
					display: block;
					@include breakpoints(tablet-landscape){
						font-size: rem-calc(48);
						line-height: rem-calc(58);	
					}
					@include breakpoints(small){
						font-size: rem-calc(36);
						line-height: rem-calc(46);	
					}
				}
				.button{
					padding: rem-calc(22 50);
					font-weight: $regular;
					border-radius: rem-calc(50);
					@include breakpoints(tablet-landscape){
						border-radius: rem-calc(30);
						padding: rem-calc(15 20);
					}
				}
				@include breakpoints(small){
					padding: rem-calc(30 15);
				}
			}
		}
	}
	@include breakpoints(small){
		padding: rem-calc(40 0 250);
		.profit_calculator_inner{
			padding: rem-calc(40 0 0);
			margin-left: rem-calc(-15);
			margin-right: rem-calc(-15);
			.business-data-col{				
				border-right: none;
				border-bottom: rem-calc(1) solid $bd_color_2;							
				padding-bottom: rem-calc(40);				
			}
			.results-data-col{
				padding-top: rem-calc(40);
			}
		}
	}
}


.profit_calculator_inner{
	.gform_wrapper {
		width: 100%;
		ul.gform_fields li.gfield{
			padding: 0;
			text-align: center;
			label{
				color: $blue;									
			}			 input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]){
				max-width: 217px;
				margin: 0 auto;
				text-align: center;
				font-size: rem-calc(36);
				line-height: rem-calc(42);
				border: none;
				font-weight: bold;
				color: $blue;
				height: 44px;				
				@include breakpoints(small){
					font-size: rem-calc(24);
					line-height: rem-calc(30);
				}
			}
			&.result-box{
				padding: rem-calc(40 15);				
				font-size: rem-calc(24);	
				line-height: rem-calc(30);
				font-weight: $regular;
				label, .gfield_description{					
					font-size: rem-calc(24);	
					line-height: rem-calc(30);
					font-weight: $regular;	
					margin-bottom: rem-calc(10);
				}				input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]){
					font-size: rem-calc(72);
					line-height: rem-calc(80);
					font-weight: $bold;
					display: block;
					height: rem-calc(90);					
					box-shadow: none;
					width: 100%;
					max-width: 100%;
					@include breakpoints(tablet-landscape){
						font-size: rem-calc(48);
						line-height: rem-calc(58);	
					}
					@include breakpoints(small){
						font-size: rem-calc(36);
						line-height: rem-calc(46);	
					}
				}
				.button{
					margin-bottom: 0;
				}
			}
		}
		.top_label div.ginput_container{
			margin-top: 2px;
		}
		.results-data-col{		
			ul.gform_fields li.gfield{				input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]){
				max-width: 100%; 
				}
			}
		}
	}


}

.profit_calculator_inner .gform_wrapper .results-data-col ul.gform_fields li.gfield label{
	display: block;
}


.profit_calculator_inner .gform_wrapper .results-data-col ul.gform_fields li.gfield [type='text'], input:disabled, input[readonly], textarea:disabled, textarea[readonly] {
	background-color: transparent;
	border: 0;
	box-shadow: none;
	pointer-events: none;
}


.profit_calculator_inner .gform_wrapper .top_label .percentage-sign div.ginput_container {
	display: inline-block;
	position: relative;
	padding: rem-calc(0 30);
	position: absolute;
	opacity: 0;
}

.profit_calculator_inner .gform_wrapper .top_label .percentage-sign {
	.textVal{
		display: block;		
		font-family: $proxima_nova;
		font-size: rem-calc(36);
		line-height: rem-calc(42);
		font-weight: bold;
		color: $blue;
		width: 100%;
		height: rem-calc(44);
		padding: 0;
		margin-top: rem-calc(2);
		@include breakpoints(small){
			font-size: rem-calc(24);
			line-height: rem-calc(30);
		}
	}
}



/* Tooltip */
.profit_calculator_inner .gftip-question-circle{	
	margin-left: rem-calc(20);
	display: inline-block;
	position: absolute;
	left: 100%;
	top: -14px;	
	&:before{
		content: 'i';
		display: inline-block;		
		font-family: $proxima_nova;
		font-size: rem-calc(16.2);
		line-height: rem-calc(26);
		vertical-align: middle;	
		background-color: $white;
		color: $blue;	
		width: rem-calc(26);
		height: rem-calc(26);
		border-radius: 100%;
	}
	&:hover{
		&:before{
			background-color: $blue;
			color: $white;
		}
	}
	@include breakpoints(small){
		top: -16px;
	}
}




.profit_calculator_inner .hint--right:focus:after, 
.profit_calculator_inner .hint--right:hover:after {
	font-family: $proxima_nova;
	background-color: $blue;
	color: $med_grey;
	font-size: rem-calc(23);
	line-height: rem-calc(35);
	padding: rem-calc(20 45 30 30);						
	width: rem-calc(470);
	min-height: rem-calc(133);	
	bottom: auto;
	text-align: left;	
	z-index: 3;
	text-shadow: none;
	white-space: inherit;	
	border-radius: 0;
	margin-left: rem-calc(65);
	margin-top: rem-calc(-32);
	z-index: 3;
	@include breakpoints(tablet-portrait){
		font-size: rem-calc(18);
		line-height: rem-calc(24);
		width: rem-calc(360);
		padding: rem-calc(15 60 30 20);		
	}
	@include breakpoints(small){		
		width: rem-calc(220);		
		left: auto;
		right: rem-calc(-60);
		margin-left: 0;
		margin-top: rem-calc(32);
		padding: rem-calc(15 15);		
		-webkit-transform: translateY(-8px);
		transform: translateY(-8px);
		margin-top: rem-calc(40);
	}
}
.profit_calculator_inner [data-hint]:before{
	border-width: rem-calc(11) ;		
}
.profit_calculator_inner .hint--light.hint--right:before {
	border-right-color: $blue;	
	margin-left: rem-calc(43);
	@include breakpoints(small){				
		-webkit-transform: rotate(90deg) translateX(10);
		transform: rotate(90deg) translateX(10px);
		margin-left: rem-calc(22);
		top: rem-calc(10);		
		@include transition(.5s, all, ease);		
	}
}

@include breakpoints(small){	
	.profit_calculator_inner .hint--right:focus:before,
	.profit_calculator_inner .hint--right:hover:before{		
		-webkit-transform: rotate(90deg) translateX(0);
		transform: rotate(90deg) translateX(0);
	}
}
























