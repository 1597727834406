.subpages_list_sec {
	padding: rem-calc(50 0);
	.subpages-list {
		li {
			margin-bottom: rem-calc(40);
			padding: 0;
			&:before, &:after {
				display: none;
			}
			&:last-child {
				margin-bottom: 0;
			}
			.subpages-content {
				text-align: center;
				max-width: rem-calc(850);
				margin: 0 auto rem-calc(45);
				position: relative;
				z-index: 1;
				.small-text {
					color: $med_grey;
					font-size: rem-calc(16);
					line-height: rem-calc(22);
					font-weight: $bold;
					text-transform: uppercase;
					letter-spacing: rem-calc(2);
					margin-bottom: rem-calc(25);
				}
				@include breakpoints(small) {
					margin-bottom: rem-calc(20);
					.small-text {
						letter-spacing: rem-calc(1);
						margin-bottom: rem-calc(15);
					}
				}
			}
			.bg_text {
				span {
					color: $white;
					text-shadow: 0 0 100px rgba(0, 0, 0, 0.15);
					@include breakpoints(small) {
						text-shadow: 0 0 100px rgba(0, 0, 0, 0.30);
					}
				}
				&:before {
					top: rem-calc(50);
				}
				.button {
					text-decoration: none;
					position: absolute;
					top: rem-calc(43);
					left: 50%;
					z-index: 1;
					@include transform-translate-hor(-50%);
					min-width: rem-calc(233);
					padding: rem-calc(15);
				}
				@include breakpoints(small) {
					height: auto;
					span {
						position: inherit;
						bottom: auto;
					}
					.button {
						position: inherit;
						top: auto;
						left: auto;
						@include transform-translate-hor(0);
					}
				}
			}
		}
	}
	@include breakpoints(tablet-landscape) {
		padding: rem-calc(40 0);
	}
}

