.titan_culture_sec{
	background-color: $blue;
	padding: rem-calc(50 0 40);
	position: relative;
	h2{
		color: $white;
		text-align: center;
		margin-bottom: rem-calc(36);
	}
	p{
		font-size: rem-calc(18);
	}
	.titan-culture-inner{
		max-width: rem-calc(1060);
		margin: 0 auto rem-calc(25);
	}
	.video-content{
		.yt-video{
			.play-button{
				span{
					width: rem-calc(56);
					height: rem-calc(56);
					line-height: rem-calc(56);
					&:before{
						border-top-width: rem-calc(5);
						border-bottom-width: rem-calc(5);
						border-left-width: rem-calc(5);
					}
				}
			}
		}
	}
	.bg-caption{
		position: relative;
		z-index: 2;
		text-align: center;
		p{
			color: $white;
		}
		.button{
			background-color: transparent;
			border-color: $white;
			&:hover, &:focus{
				background-color: $white;
				color: $blue;
			}
		}
	}
	.bg_text {
		position: absolute;
		bottom: 0;
		margin-bottom: rem-calc(15);
		span{
			color: $blue;
		}
	}
	@include breakpoints(large){
		.titan-culture-inner{
			margin-bottom: rem-calc(50);
		}
	}
	@include breakpoints(small){
		padding: rem-calc(40 0 0);
		h2{
			margin-bottom: rem-calc(30);
		}
		.titan-culture-inner{
			margin-bottom: rem-calc(30);
		}
		.video-content{
			margin-bottom: 0;
		}
	}
}

