.main_content_sec{ 
	padding: rem-calc(42 0 0);
	position: relative;	
	overflow: hidden;
	.intro-text{
		text-align: center;
		margin-bottom: rem-calc(24);
		max-width: rem-calc(1020);
	}
	h1{
		margin-bottom: rem-calc(10);
	}
	h2{
		font-size: rem-calc(30);	
		line-height: rem-calc(36);	
		color: $blue;
		margin-bottom: rem-calc(10);	
	}
	.bg_text{
		text-align: center;
		height: rem-calc(155);
		span{
			font-size: rem-calc(224);
			line-height: rem-calc(155);
		}
		@include breakpoints(extra-large-x){ 
			height: rem-calc(135);
			span{
				font-size: rem-calc(192);
				line-height: rem-calc(125);
			}
		}
		@include breakpoints(extra-large){ 
			height: rem-calc(113);
			span{
				font-size: rem-calc(165);
				line-height: rem-calc(108);
			}
		}
		@include breakpoints(large){
			height: rem-calc(92);
			span{
				font-size: rem-calc(135);
				line-height: rem-calc(86);
			}
		}
		@include breakpoints(tablet-landscape){
			height: rem-calc(72);
			span{
				font-size: rem-calc(104);
				line-height: rem-calc(70);
			}
		}
		@include breakpoints(small){
			height: rem-calc(80);
			span{
				font-size: rem-calc(100);
				line-height: rem-calc(80);
			}
		}
		/*@include breakpoints(x-small){
			height: rem-calc(60);
			span{
				font-size: rem-calc(80);
				line-height: rem-calc(60);
			}
		}*/
		@include breakpoints(extra-small){
			height: rem-calc(40);
			span{
				font-size: rem-calc(60);
				line-height: rem-calc(40);
			}
		}
	}
	@include breakpoints(tablet-portrait){
		padding: rem-calc(40 0 0);
	}
	@include breakpoints(small){
		padding: rem-calc(40 0 20);
	}
}
