@charset 'utf-8';


@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
//@include foundation-grid;
//@include foundation-flex-grid;

@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

/*
  My custom styles:
  Add your scss files below to structure your project styles
*/

// Global
@import "global/accessibility";
@import "global/colors";
@import "global/wp-admin";
@import "global/wp-overrides";

// Modules
@import "modules/navigation";
@import "modules/content";
@import "modules/footer";
@import "modules/editor-style";
@import "modules/owl.carousel.min"; 
@import "modules/owl.theme.default.min";
@import "modules/global";
@import "modules/animate";
@import "modules/style";

// Content Modules
@import "content-modules/menu";
@import "content-modules/banner";
@import "content-modules/specializing_sec";
@import "content-modules/meet_team_titan_sec";
@import "content-modules/meet_titan_bot_sec";
@import "content-modules/case_studies_slider";
@import "content-modules/google_partner_sec";
@import "content-modules/tips_trends_sec";
@import "content-modules/main_content_sec";
@import "content-modules/image_content_sec";
@import "content-modules/why_paid_media_sec";
@import "content-modules/synergy_sec";
@import "content-modules/content_image_row";
@import "content-modules/blog_temp";
@import "content-modules/video_wrap";
@import "content-modules/credentials_awards";
@import "content-modules/subpages_list_sec";
@import "content-modules/timeline_sec";
@import "content-modules/our_team_sec";
@import "content-modules/titan_culture_sec";
@import "content-modules/titan_benefits_sec";
@import "content-modules/current_opportunities_sec";
@import "content-modules/contact_page";
@import "content-modules/case_study_single";
@import "content-modules/profit_calculator_sec";
@import "content-modules/footer";

// Components
@import "components/buttons";
@import "components/cards";
@import "components/featured-image";
@import "components/dividers";
@import "components/links";

// Templates
@import "templates/front";
@import "templates/kitchen-sink";
