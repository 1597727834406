.image_content_sec{
	&.istitle{
		h2{
			text-align: center;
			padding-top: rem-calc(50);
			margin-bottom: rem-calc(50);
		}
	}


	.side-img-content{
		position: relative;
		.content-area{
			max-width: rem-calc(400);
			margin: 0 auto;
			position: relative;
			z-index: 2;
			/*h3{
				font-weight: $bold;
			}*/
		}
		.image-content-area{
			max-width: rem-calc(400);
			margin-left: auto;
			margin-right: auto;
			position: relative;
			z-index: 2;
		}
		.image_content_row{
			display: flex;
			flex-wrap: wrap;
			width: 100%;
		}
		.image-col{
			width: 50%;
			padding: rem-calc(120 30);
			min-height: rem-calc(600);
			ul{
				li{
					color: $white;
					font-size: rem-calc(26);
					font-weight: $bold;
					display: block;
					&:before{
						border-color: $med_grey;
					}
					&:after{
						border-color: $white;
					}
				}
			}
		}
		.img-part{
			position: absolute;
			top: 0;
			left: 0;
			width: 50%;
			height: 100%;
			@extend .bgimage;
			@include box-shadow(0, 0, 15px, 0, rgba(0,0,0,0.25));
		}
		.content-col{
			width: 50%;
			padding: rem-calc(120 30);
			margin-left: auto;
			//@include flex;
			align-items: center;
		}
		&:nth-child(2n){
			.image-col{
				order: 2;
			}
			.img-part{
				left: auto;
				right: 0;
			}
			.content-col{
				order: 1;
			}
		}
	}
	&.right{
		.side-img-content{
			.image-col{
				order: 2;
			}
			.img-part{
				left: inherit;
				right: 0;
			}
			.content-col{
				order: 1;
			}
			&:nth-child(2n){
				.image-col{
					order: 1;
				}
				.img-part{
					left: 0;
					right: auto;
				}
				.content-col{
					order: 2;
				}
			}
		}
	}


	&.overlay{
		.side-img-content{
			.img-part{
				&:before{
					content: '';
					display: inline-block;
					width: 100%;
					height: 100%;
					background-color: $blue;
					opacity: 0.48;
					position: absolute;
					top: 0;
					right: 0;
					z-index: 1;
				}
			}
		}
	}


	@include breakpoints(tablet-landscape){
		.side-img-content{
			.image-col{
				min-height: rem-calc(500);
				ul{
					margin-bottom: 0;
					li{
						font-size: rem-calc(20);
					}
				}
			}
		}
	}
	@include breakpoints(tablet-medium){
		.side-img-content{
			.content-area{
				max-width: inherit;
			}
			.image-content-area{
				max-width: inherit;
			}
			.image_content_row{
				display: block;
			}
			.image-col{
				width: auto;
				margin-left: rem-calc(-15);
				margin-right: rem-calc(-15);
				min-height: rem-calc(400);
				position: relative;
			}
			.img-part{
				height: 100%;
				width: 100%;
				bottom: 0;
				padding: rem-calc(50 30);
			}
			.content-col{
				width: 100%;
				padding: rem-calc(30 0);
			}
			&:nth-child(2n){
				.image-col{
					order: inherit;
				}
				.img-part{
					left: auto;
					right: 0;
				}
				.content-col{
					order: inherit;
				}
			}
		}
		&.right{
			.side-img-content{
				.image-col{
					order: inherit;
				}
			}
		}
	}
	@include breakpoints(small){
		.side-img-content{
			.image-col{
				min-height: rem-calc(300);
			}
			.image-col{
				padding: rem-calc(30 15);
			}
		}
	}
}

