.tips_trends_sec {
	background-color: $light_grey_bg;
	padding: rem-calc(50 0 60);
	.intro-text {
		text-align: center;
		margin-bottom: rem-calc(60);
	}
	a {
		display: block;
		.thumb-bg {
			@extend .bgimage;
			height: rem-calc(210);
			position: relative;
			&:before {
				content: '';
				display: inline-block;
				background-color: $blue;
				opacity: 0.75;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 1;
				@include transition(.5s, all, ease);
			}
			.pro-img {
				background-color: $white;
				@extend .bgimage;
				width: rem-calc(55);
				height: rem-calc(55);
				display: inline-block;
				margin: rem-calc(20 50);
				position: absolute;
				bottom: 0;
				z-index: 1;
				border: 2px solid $white;
				border-radius: 100%;
			}
		}
		h3 {
			color: $dark_grey;
			font-weight: $bold;
			padding: rem-calc(25 50);
			font-size: rem-calc(24);
			line-height: rem-calc(30);
		}
		&:hover {
			.thumb-bg {
				&:before {
					opacity: 0.5;
				}
			}
		}
	}
	.owl-nav {
		div {
			top: 32%;
		}
	}
	.owl-dots {
		position: static;
		bottom: auto;
		.owl-dot {
			background-color: $blue;
			border-color: $blue;
			&.active {
				background-color: transparent;
			}
		}
	}
	@include breakpoints(tablet-landscape) {
		a {
			h3 {
				padding: rem-calc(15 30);
			}
		}
	}
	@include breakpoints(small) {
		padding: rem-calc(40 0 25);
		.intro-text {
			margin-bottom: rem-calc(30);
		}
		a {
			h3 {
				padding: rem-calc(15);
			}
		}
		.tips-trends-slider {
			padding-bottom: rem-calc(70);
		}
		.owl-nav {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			text-align: center;
			height: rem-calc(70);
			padding: rem-calc(15 0);
			div {
				position: relative;
				left: auto;
				right: auto;
				top: auto;
				display: inline-block;
				transform: inherit;
				bottom: auto;
				margin: rem-calc(0 10);
				&.owl-prev {
					left: auto;
				}
				&.owl-next {
					right: auto;
				}
			}
		}
	}
}

