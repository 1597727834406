.home_banner {
	@extend .bgimage;
	background-color: $dark_gray;
	width: 100%;
	position: relative;
	overflow: hidden;
	height: rem-calc(480);
	.video {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		min-height: 100%;
		min-width: 100%;
		overflow: hidden;
		object-fit: cover;
	}
	.mob-banner-bg {
		@extend .bgimage;
		height: 100%;
		display: none;
	}
	.banner-inner {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		@include flex;
		@include flex-align(center, center);
		text-align: center;
	}
	.button-wrap {
		position: absolute;
		bottom: rem-calc(40);
		left: 0;
		right: 0;
		text-align: center;
		display: none;
	}
	.button {
		background-color: $white;
		color: $orange;
		border-color: $orange;
		font-size: rem-calc(24);
		padding: rem-calc(22 45);
		border-radius: rem-calc(35);
		.arrow-right{
			border-left-color: $orange;
		}
		&:hover, &:focus {
			background-color: $orange;
			color: $white;
			border-color: $white;
			.arrow-right{
				border-left-color: $white;
			}
		}
	}
	/* 1366 */
	@include breakpoints(min-x-large) {
		height: rem-calc(480);
		.video {
			object-position: center top;
		}
	}
	/* 1440 */
	@include breakpoints(xxlarge) {
		height: rem-calc(560);
	}
	/* 1920 */
	@include breakpoints(extra-large-xx) {
		height: rem-calc(640);
	}
	/* 2540 */
	@include breakpoints(double-extra-x-large) {
		height: rem-calc(720);
	}
	@include breakpoints(tablet-portrait) {
		.video {
			display: none;
		}
		.mob-banner-bg {
			display: block;
		}
		.button-wrap {
			bottom: rem-calc(30);
		}
		.button {
			padding: rem-calc(15 30);
		}
	}
	@include breakpoints(small) {
		height: rem-calc(300);
		.button {
			font-size: rem-calc(16);
			padding: rem-calc(12 15);
		}
	}
}







.inner_banner {
	@extend .bgimage;
	@include flex;
	@include flex-align(flex-start, center);
	height: rem-calc(358);
	position: relative;
	&.inner_banner {
		width: 100%;
	}
	.grid-container {
		width: 100%;
	}
	&.banner_overlay {
		&:before {
			content: '';
			display: inline-block;
			/* ms ( IE 10.0+ ) */
			background: -ms-linear-gradient(180deg, rgba(0, 12, 66, 0.95) 0.00%, rgba(0, 0, 0, 0) 100%);
			/* WebKit (Chrome 10.0+, safari 5.1+ )*/
			background: -webkit-linear-gradient(180deg, rgba(0, 12, 66, 0.95) 0.00%, rgba(0, 0, 0, 0) 100%);
			/* Moz ( Moz 3.6+ )*/
			background: -moz-linear-gradient(180deg, rgba(0, 12, 66, 0.95) 0.00%, rgba(0, 0, 0, 0) 100%);
			/* Opera ( opera 11.6+ )*/
			background: -o-linear-gradient(180deg, rgba(0, 12, 66, 0.95) 0.00%, rgba(0, 0, 0, 0) 100%);
			/* W3C Markup */
			background: linear-gradient(180deg, rgba(0, 12, 66, 0.95) 0.00%, rgba(0, 0, 0, 0) 100%);
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}
	.inner_banner_content {
		position: relative;
		z-index: 1;
		.page-title {
			color: $white;
			font-size: rem-calc(80);
			line-height: rem-calc(80);
			font-weight: $black;
			margin-top: rem-calc(30);
			text-align: center;
			text-transform: uppercase;
		}
	}
	@include breakpoints(tablet-portrait) {
		.inner_banner_content {
			.page-title {
				font-size: rem-calc(60);
				line-height: rem-calc(60);
			}
		}
	}
	@include breakpoints(small) {
		height: rem-calc(240);
		.inner_banner_content {
			.page-title {
				font-size: rem-calc(40);
				line-height: rem-calc(40);
			}
		}
	}
	@include breakpoints(x-small) {
		height: rem-calc(140);
		.inner_banner_content {
			.page-title {
				font-size: rem-calc(28);
				line-height: rem-calc(38);
			}
		}
	}
}

.single_post_hero_banner {
	@extend .bgimage;
	text-align: center;
	padding: rem-calc(55 0 30);
	position: relative;
	&:before {
		content: '';
		display: inline-block;
		background-color: $blue;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0.8;
	}
	.inner_banner_content {
		width: 100%;
		position: relative;
		z-index: 1;
		.page-title {
			color: $white;
			font-size: rem-calc(48);
			line-height: rem-calc(54);
			font-weight: $bold;
			margin-bottom: rem-calc(30);
		}
		h1{
			color: $white;
		}
		.product-data {
			font-family: $proxima_nova;
			font-weight: $regular;
			color: $white;
			font-style: $italic;
			.author-img {
				background-color: $white;
				width: rem-calc(108);
				height: rem-calc(108);
				display: inline-block;
				position: relative;
				overflow: hidden;
				border: solid rem-calc(2) $white;
				border-radius: 100%;
				margin-bottom: rem-calc(7);
			}
		}

	}
	@include breakpoints(tablet-portrait) {
		.inner_banner_content {
			.page-title {
				font-size: rem-calc(36);
				line-height: rem-calc(30);
			}
		}
	}
	@include breakpoints(small) {
		.inner_banner_content {
			.page-title {
				font-size: rem-calc(30);
				line-height: rem-calc(36);
			}
		}
	}
}




@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.home_banner{
		.video{
			width: auto;
			height: auto;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
		}
	}
	@include breakpoints(extra-large) {
		.home_banner{
			.video{
				transform: translate(-50%,-50%) scale(1.2);
			}
		}
	}
	@include breakpoints(large) {
		.home_banner{
			height: rem-calc(480);
			.button-wrap{
				bottom: rem-calc(20);
			}
		}
	}
}

.ie .home_banner .video {
	width: auto;
	height: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	@include breakpoints(extra-large) {
		transform: translateY(-50%,-50%) scale(1.2);
	}
}
@include breakpoints(large) {
	.ie .home_banner {
		height: rem-calc(480);
	}
	.ie .home_banner .button-wrap{
		bottom: rem-calc(20);
	}
}
