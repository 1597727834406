.synergy_sec {
	padding: rem-calc(50 0 70);
	height: 100%;
	.supporting_inner {
		padding: rem-calc(130 104);
		/*margin-bottom: rem-calc(150);*/
		height: 100%;
		.circle_round {
			@include flex;
			align-items: flex-start;
			justify-content: center;
			text-align: center;
			position: relative;
			width: 100%;
			height: rem-calc(615);
			.supporting_center {
				max-width: rem-calc(450);
				margin: rem-calc(15) auto 0;
				width: 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				@include transform-translate(-50%, -50%);
				h2 {
					margin-bottom: rem-calc(50);
				}
				p {
					color: $blue;
					margin-bottom: rem-calc(30);
					img {
						max-width: rem-calc(38);
						vertical-align: middle;
						margin-right: rem-calc(5);
					}
					strong {
						font-size: rem-calc(30);
						line-height: rem-calc(40);
						color: $dark_grey;
					}
				}
			}
			.all_circles {
				position: absolute;
				width: 100%;
				border: solid rem-calc(1) $grey;
				left: 0;
				right: 0;
				max-width: rem-calc(730);
				height: 100%;
				border-radius: 100%;
				margin: 0 auto;
				.circle_box {
					font-family: $proxima_nova;
					color: $white;
					font-size: rem-calc(20);
					line-height: rem-calc(30);
					background-color: $blue;
					display: flex;
					align-items: center;
					justify-content: center;
					width: rem-calc(240);
					height: rem-calc(240);
					position: absolute;
					border-radius: 100%;
					cursor: pointer;
					padding: rem-calc(30);
					&:before {
						content: "";
						display: inline-block;
						background: url(../images/arrow.png) no-repeat 0 0;
						background-size: 100%;
						width: rem-calc(35);
						height: rem-calc(45);
						position: absolute;
					}
					&:after {
						content: "";
						display: inline-block;
						background-color: transparent;
						border-radius: 100%;
						position: absolute;
						top: -20px;
						right: -20px;
						bottom: -20px;
						left: -20px;
						border: solid rem-calc(20) $white;
						box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.35);
						opacity: 0;
					}
					&:first-child {
						top: 0;
						left: 50%;
						@include transform-translate-hor(-50%);
						margin-top: rem-calc(-104);
						&:before {
							top: rem-calc(138);
							left: rem-calc(316);
						}
					}
					&:nth-child(2) {
						top: 50%;
						right: 0;
						@include transform-translate-ver(-50%);
						margin-right: rem-calc(-180);
						&:before {
							top: rem-calc(341);
							right: rem-calc(295);
							@include transform-rotate(122deg);
						}
					}
					&:nth-child(3) {
						bottom: 0;
						left: 50%;
						@include transform-translate-hor(-50%);
						margin-bottom: rem-calc(-106);
						&:before {
							top: rem-calc(62);
							left: rem-calc(-99);
							@include transform-rotate(180deg);
						}
					}
					&:nth-child(4) {
						top: 50%;
						left: 0;
						@include transform-translate-ver(-50%);
						margin-left: rem-calc(-180);
						&:before {
							top: rem-calc(-129);
							left: rem-calc(272);
							@include transform-rotate(302deg);
						}
					}
					&.active {
						background-color: $white;
						color: $blue;
						box-shadow: 0 0 0 4px $grey_border inset;
						&:after {
							opacity: 1;
						}
					}
				}
			}
		}
	}
	@include breakpoints(small) {
		.supporting_inner {
			.circle_round {
				.supporting_center {
					p {
						strong {
							font-size: rem-calc(24);
							line-height: rem-calc(34);
						}
					}
				}
			}
		}
	}
	@include breakpoints(large) {
		.supporting_inner {
			padding: rem-calc(120 0);
			.circle_round {
				height: rem-calc(520);
				.supporting_center {
					max-width: rem-calc(360);
					h2 {
						font-size: rem-calc(24);
						line-height: rem-calc(34);
						margin-bottom: rem-calc(30);
					}
				}
				.all_circles {
					max-width: rem-calc(600);
					.circle_box {
						font-size: rem-calc(18);
						line-height: rem-calc(24);
						width: rem-calc(200);
						height: rem-calc(200);
						&:after {
							top: -15px;
							right: -15px;
							bottom: -15px;
							left: -15px;
							border: solid rem-calc(15) $white;
							box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.35);
							opacity: 0;
						}
						&:first-child {
							&:before {
								top: rem-calc(134);
								left: rem-calc(265);
							}
						}
						&:nth-child(2) {
							margin-right: rem-calc(-80);
							&:before {
								top: rem-calc(269);
								right: rem-calc(170);
							}
						}
						&:nth-child(3) {
							&:before {
								top: rem-calc(11);
								left: rem-calc(-110);
							}
						}
						&:nth-child(4) {
							margin-left: rem-calc(-80);
							&:before {
								top: rem-calc(-109);
								left: rem-calc(163);
							}
						}
					}
				}
			}
		}
	}
	@include breakpoints(tablet-medium) {
		.supporting_inner {
			padding: rem-calc(30 0 10);
			margin-bottom: 0;
			.circle_round {
				height: inherit;
				.supporting_center {
					position: inherit;
					top: auto;
					left: auto;
					transform: inherit;
				}
				.all_circles {
					display: none;
				}
			}
		}
	}
}

.ppc_strategy {
	.ppc_strategy_inner {
		padding-left: rem-calc(90);
		a {
			color: $blue;
			&:hover {
				color: $dark_blue;
			}
		}
		.ppc_strategy_content {
			max-width: rem-calc(400);
			ul {
				li {
					color: $blue;
				}
			}
		}
	}
	.ad-column {
		text-align: center;
		img {
			margin-bottom: rem-calc(30);
		}
		.vs-text {
			font-family: $proxima_nova;
			font-size: rem-calc(16);
			color: $blue;
			line-height: rem-calc(35);
			display: inline-block;
			width: rem-calc(35);
			height: rem-calc(35);
			border: solid rem-calc(1) $light_grey;
			border-radius: 100%;
			text-align: center;
			vertical-align: middle;
			margin-bottom: rem-calc(30);
		}
	}
	@include breakpoints(tablet-landscape-x) {
		.ppc_strategy_inner {
			padding-left: 0;
		}
	}
	@include breakpoints(tablet-landscape) {
		.ppc_strategy_inner {
			padding-left: 0;
			padding-right: rem-calc(15);
			.ppc_strategy_content {
				max-width: inherit;
			}
		}
	}
}

.circle_slidtxt_wrap {
	display: none;
	margin: 0 -15px;
	@include breakpoints(tablet-medium) {
		display: block;
	}
}

.circle_slidtxt {
	background-color: $blue;
	padding: rem-calc(30 15 70);
	margin-bottom: rem-calc(30);
	p {
		margin-bottom: 0;
	}
	.owl-dots {
		bottom: rem-calc(15);
		left: 0;
		.owl-dot {
			&.active {
				border-color: $white;
			}
		}
	}
}



