.our_team_sec {
	padding: rem-calc(0 0 20);
	.our_team_top {
		background-color: $blue;
		padding: rem-calc(58 0 0);
		position: relative;
		margin-bottom: rem-calc(80);
		@include breakpoints(small) {
			margin-bottom: rem-calc(50);
		}
	}
	p {
		color: $white;
	}
	.text-content {
		max-width: rem-calc(850);
		margin: 0 auto;
		text-align: center;
	}
	.logos-container {
		max-width: rem-calc(1035);
		width: 100%;
		background-color: $white;
		padding: rem-calc(50 30 30);
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin: 0 auto;
		position: relative;
		bottom: rem-calc(-80);
		@include box-shadow(0, 3px, 50px, 0, rgba(0, 0, 0, 0.30));
		li {
			width: 20%;
			display: block;
			padding: rem-calc(0 20);
			&:before, &:after {
				display: none;
			}
			a {
				display: block;
				margin: 0 auto;
				max-width: rem-calc(160);
			}
			img {
				width: 100%;
			}
		}
		@include breakpoints(tablet-landscape) {
			li {
				width: 25%;
			}
		}
		@include breakpoints(small) {
			bottom: rem-calc(-50);
			padding: rem-calc(30 10 10);
			li {
				width: 33.333%;
				padding: rem-calc(0 10);
			}
		}
		@include breakpoints(x-small) {
			li {
				width: 50%;
			}
		}
		@include breakpoints(extra-small) {
			li {
				width: 100%;
			}
		}
	}
	.bio-popup-slider {
		padding: rem-calc(100 0 0);
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: rem-calc(0 -30);
		li {
			width: 25%;
			padding: rem-calc(0 15);
			margin-bottom: rem-calc(60);
			text-align: center;
			display: block;
			&:before, &:after {
				display: none;
			}
			a.bioWrap {
				font-family: $proxima_nova;
				display: block;
				/*width: rem-calc(290);*/
				text-decoration: none;
				outline: none;
			}
		}
		@include breakpoints(max-x-large) {
			margin: 0;
			li {
				a.bioWrap {
					.img_circle {
						width: rem-calc(241);
						height: rem-calc(240);
					}
				}
			}
		}
		@include breakpoints(large) {
			margin: 0;
			li {
				a.bioWrap {
					.img_circle {
						width: rem-calc(221);
						height: rem-calc(220);
					}
				}
			}
		}
		@include breakpoints(tablet-landscape) {
			padding: rem-calc(60 0 0);
			li {
				width: 33.333333333%;
				margin-bottom: rem-calc(40);
			}
		}
		@include breakpoints(small) {
			li {
				width: 50%;
				margin-bottom: rem-calc(30);
				a.bioWrap {
					.img_circle {
						border-width: rem-calc(8);
					}
				}
			}
		}
		@include breakpoints(x-small) {
			li {
				width: 50%;
				a.bioWrap {
					.img_circle {
						width: rem-calc(200);
						height: rem-calc(200);
					}
				}
			}
		}
		@include breakpoints(extra-small) {
			li {
				width: 100%;
			}
		}
	}
}

.img_circle {
	width: rem-calc(291);
	height: rem-calc(290);
	border-radius: 100%;
	border: solid rem-calc(12) $white;
	@include box-shadow(0, 0, 50px, 0, rgba(0, 0, 0, 0.30));
	margin: 0 auto rem-calc(20);
	overflow: hidden;
	position: relative;
	@include transition(.5s, all, ease);
	span {
		display: block;
		height: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		@include transition(.5s, all, ease);
		border-radius: 100%;
		&.img-hover {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			opacity: 0;
		}
	}
	&:hover {
		border-color: $blue;
		box-shadow: none;
		span {
			&.img-hover {
				opacity: 1;
			}
		}
	}
}

.bio-text {
	font-size: rem-calc(18);
	color: $blue;
	line-height: rem-calc(24);
	display: block;
	font-style: $italic;
}

.bio-title {
	display: block;
}

/* Reveal - PopUp */
.reveal,
.reveal-modal {
	font-family: $proxima_nova;
	max-width: rem-calc(1093);
	width: 100%;
	padding: 0;
	overflow: visible;
	margin-bottom: rem-calc(50);
	border: none;
	background-color: transparent;
	padding-top: rem-calc(145);
	.popup_main {
		background-color: $white;
		padding: rem-calc(0 0 63);
		position: relative;
		.team-img {
			text-align: center;
			margin-bottom: rem-calc(50);
			.img_circle {
				width: rem-calc(290);
				height: rem-calc(290);
				border-radius: 100%;
				border: solid rem-calc(12) $white;
				@include box-shadow(0, 0, 50px, 0, rgba(0, 0, 0, 0.30));
				margin: rem-calc(-145) auto rem-calc(30);
				display: inline-block;
			}
			.bio-text {
				font-weight: $bold;
				font-style: normal;
			}
			.bio-title {
				font-size: rem-calc(18);
				line-height: rem-calc(24);
				display: block;
				margin-bottom: rem-calc(30);
				color: $grey;
			}
		}
		.team-row {
			display: flex;
			align-items: flex-start;
			width: 100%;
			margin-bottom: rem-calc(55);
			p {
				letter-spacing: rem-calc(-0.6);
			}
			.team-left {
				width: 60.5%;
				padding: rem-calc(0 70 0 106);
			}
			.team-right {
				background-color: $light_grey;
				width: 39.5%;
				padding: rem-calc(21 50);
				min-height: rem-calc(230);
				.block-title {
					color: $med_grey;
					font-family: $proxima_nova;
					font-size: rem-calc(16);
					font-weight: $bold;
					text-transform: uppercase;
					text-align: center;
					display: block;
					letter-spacing: rem-calc(1.5);
					margin-bottom: rem-calc(20);
				}
				ul li {
					font-weight: $bold;
					margin-bottom: rem-calc(6);
				}
			}
		}
		.popup_nav {
			display: none;
			button {
				background-color: $white;
				width: rem-calc(45);
				height: rem-calc(45);
				line-height: rem-calc(45);
				vertical-align: middle;
				text-align: center;
				border-radius: 100%;
				position: absolute;
				top: 50%;
				@include transform-translate-ver(-50%);
				font-size: 0;
				background-repeat: no-repeat;
				background-position: center center;
				@include box-shadow(0, 0, 35px, 0, rgba(0, 0, 0, 0.30));
				cursor: pointer;
				&.prev_btn {
					left: rem-calc(30);
					background-image: url('../images/prev-arrow.svg');
					background-size: 11px;
				}
				&.next_btn {
					right: rem-calc(30);
					background-image: url('../images/next-arrow.svg');
					background-size: 11px;
				}
			}
		}
		.social-icons {
			text-align: center;
			margin-bottom: rem-calc(40);
			li {
				margin: rem-calc(0 13);
			}
		}
		.close-button {
			width: rem-calc(81);
			height: rem-calc(81);
			background-color: $blue;
			top: 0;
			right: 0;
			font-size: 0;
			line-height: 0;
			@include transition(.5s, all, ease);
			span {
				display: block;
				height: 100%;
			}
			span:before, span:after {
				position: absolute;
				left: 50%;
				top: rem-calc(16);
				content: ' ';
				height: rem-calc(50);
				width: rem-calc(1);
				background-color: $white;
			}
			span:before {
				transform: rotate(45deg);
			}
			span:after {
				transform: rotate(-45deg);
			}
			&:hover {
				opacity: 0.8;
			}
		}
	}
	@include breakpoints(tablet-landscape) {
		.popup_main {
			.team-row {
				.team-left {
					padding: rem-calc(0 50);
				}
			}
		}
	}
	@include breakpoints(tablet-medium) {
		padding-top: rem-calc(110);
		.popup_main {
			padding: rem-calc(0 0 40);
			.team-img {
				margin-bottom: rem-calc(30);
				.img_circle {
					width: rem-calc(220);
					height: rem-calc(220);
					border-width: rem-calc(8);
					margin: rem-calc(-110) auto rem-calc(30);
				}
			}
			.team-row {
				margin-bottom: rem-calc(40);
				display: block;
				.team-left {
					width: 100%;
					padding: rem-calc(20);
				}
				.team-right {
					padding: rem-calc(20);
					width: 100%;
				}
			}
			.popup_nav {
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;
				text-align: center;
				padding: rem-calc(12 15);
				button {
					position: relative;
					top: auto;
					width: rem-calc(38);
					height: rem-calc(38);
					@include transform-translate-ver(0);
					margin: rem-calc(0 15);
					&.prev_btn {
						left: auto;
						background-size: 9px;
					}
					&.next_btn {
						right: auto;
						background-size: 9px;
					}
				}
			}
			.close-button {
				width: rem-calc(50);
				height: rem-calc(50);
				span:before, span:after {
					top: rem-calc(11);
					height: rem-calc(30);
				}
			}
		}
	}
	@include breakpoints(small) {
		margin: 0;
		overflow-y: auto;
		padding-top: 0;
		background-color: $white;
		.popup_main {
			padding: 0;
			.team-img {
				.img_circle {
					width: rem-calc(180);
					height: rem-calc(180);
					margin: rem-calc(30) auto;
				}
			}
			.team-row {
				.team-left {
					padding-top: 0;
				}
			}
		}
	}
}

.reveal.reveal_form_wrap {
	padding: 0;
	.popup_main.popup_form {
		background-color: $light_grey;
		padding: rem-calc(100 30 30);
	}
	@include breakpoints(tablet-medium) {
		.popup_main.popup_form {
			padding: rem-calc(50 30 30);
		}
	}
	@include breakpoints(small) {
		background-color: $light_grey;
		.popup_main.popup_form {
			padding: rem-calc(50 15 30);
		}
	}
}

.reveal.video_reveal {
	padding: rem-calc(30);
	outline: none;
	.popup_main {
		padding: rem-calc(70 30 40);
		.modal-videowrap {
			position: relative;
			padding-bottom: 54.65%;
			iframe {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
		}
		.close-button {
			border-radius: 50%;
			margin-top: rem-calc(-40);
			margin-right: rem-calc(-15);
			&:hover, &:focus {
				opacity: 1;
				transform: rotate(90deg);
			}
		}
	}
	@include breakpoints(small) {
		.popup_main {
			padding: rem-calc(70 0 0);
			.close-button {
				margin-top: 0;
				margin-right: 0;
			}
		}
	}
}


.reveal-modal-sec {
	padding: rem-calc(50 0);
	.reveal-modal {
		max-width: none;
		margin-left: rem-calc(-15);
		margin-right: rem-calc(-15);
		margin-bottom: 0;
		width: auto;
	}
}








