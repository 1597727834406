.blog_sec {
	text-align: center;
	padding: rem-calc(30 0 50);
	.blog-row {
		margin-bottom: 0;
	}
	.blog-col {
		margin-bottom: rem-calc(45);
	}
	.blog-link {
		display: block;
		text-align: center;
		text-decoration: none;
		.blog-thumb-wrap {
			position: relative;
			overflow: hidden;
			height: rem-calc(210);
			margin-bottom: rem-calc(25);
			.blog-thumb {
				@extend .bgimage;
				width: 100%;
				height: 100%;
				@include transition(.5s, all, ease);
			}
		}
		h3 {
			font-size: rem-calc(24);
			line-height: rem-calc(30);
			font-weight: $bold;
			color: $dark_grey;
			padding: rem-calc(0 15);
			@include transition(.5s, all, ease);
		}
		&:hover {
			.blog-thumb-wrap {
				.blog-thumb {
					@include transform-scale(1.1);
				}
			}
			h3 {
				color: $blue;
			}
		}
	}
	.more-items {
		margin-bottom: 0;
	}
	.pagination {
		width: 100%;
		text-align: right;
		display: block;
		margin-bottom: 0;
		padding-top: rem-calc(30);
		.previous-page, .next-page {
			a {
				display: inline-block;
				color: $primary_color;
				text-transform: uppercase;
				font-family: $proxima_nova;
				font-size: rem-calc(18);
				font-weight: $bold;
				&:hover, &:focus {
					background: none;
				}
			}
		}
		.previous-page {
			a {
				float: left;
			}
		}
		.next-page {
			a {
				float: right;
			}
		}
		a, span {
			&.page-numbers {
				font-family: $proxima_nova;
				font-weight: $regular;
				font-size: rem-calc(22);
				color: $grey_bg_text;
				min-width: rem-calc(61);
				height: rem-calc(55);
				line-height: rem-calc(55);
				vertical-align: middle;
				display: inline-block;
				text-align: center;
				border: solid rem-calc(1) $grey_bg_text;
				margin-bottom: 0;
				padding: rem-calc(0 15);
				margin-left: rem-calc(5);
				&:hover, &.current {
					background-color: $grey_bg_text;
					color: $white;
				}
				@include breakpoints(small) {
					font-size: rem-calc(22);
					min-width: rem-calc(42);
					height: rem-calc(42);
					line-height: rem-calc(42);
				}
			}
		}
		@include breakpoints(tablet-landscape) {
			padding: rem-calc(15 0 0);
		}
		@include breakpoints(small) {
			a, span {
				&.page-numbers {
					font-size: rem-calc(18);
				}
			}
		}
	}
	@include breakpoints(tablet-portrait) {
		padding: rem-calc(30 0 40);
	}
	@include breakpoints(small) {
		padding: rem-calc(30 0);
		.blog-col {
			margin-bottom: rem-calc(20);
		}
		.blog-link {
			.blog-thumb-wrap {
				margin-bottom: rem-calc(15)
			}
			h3 {
				font-size: rem-calc(18);
				line-height: rem-calc(24);
			}
		}
	}
}

.blog_detail_wrap {
	padding: rem-calc(24 0 40);
	h2 {
		padding-top: rem-calc(35);
		margin-bottom: rem-calc(35);
	}
	.blog-inner-content {
		max-width: rem-calc(960);
		margin: 0 auto;
		padding: rem-calc(0 15);
	}
	.back-link {
		font-family: $proxima_nova;
		display: inline-block;
		color: $blue;
		font-weight: $black;
		position: relative;
		padding-left: rem-calc(15);
		margin-left: rem-calc(6);
		margin-bottom: rem-calc(40);
		&:before {
			content: '';
			display: inline-block;
			width: 0;
			height: 0;
			border-top: rem-calc(4) solid transparent;
			border-bottom: rem-calc(4) solid transparent;
			border-right: rem-calc(4) solid $blue;
			position: absolute;
			top: rem-calc(9);
			left: 0;
		}
		&:hover, &:focus {
			text-decoration: underline;
			color: $dark_blue;
			border-right-color: $dark_blue;
		}
	}
	img {
		/*width: 100%;*/
		margin: rem-calc(20 0 40);
	}
	@include breakpoints(small) {
		h2 {
			padding-top: rem-calc(25);
			margin-bottom: rem-calc(25);
		}
		img {
			margin: rem-calc(10 0 20);
		}
		.back-link {
			margin-bottom: rem-calc(20);
		}
	}
}












