.content_image_row{
	@extend .bgimage;
	.overlay{
		background-color: rgba(41,57,133,0.9);
	}
	h2{
		color: $white;
	}
	p{
		color: $grey_text;
	}
	.contentImgWrap{
		@include flex;		
		width: 100%;
		position: relative;
		min-height: rem-calc(360);
		.contentCol{
			width: 48%;
			padding: rem-calc(70 0 30 100);			
		}
		.imgCol{
			width: 52%;
			img{
				position: absolute;
				top: rem-calc(-40);
				max-width: 100%;
			}
		}	
		&.left{
			.contentCol{
				order: 2;				
				margin-left: auto;
				margin-right: rem-calc(50);
			}
			.imgCol{
				order: 1;				
			}	
		}
	}
	@include breakpoints(extra-large){
		.contentImgWrap{
			.contentCol{
				padding: rem-calc(70 30);
			}
			.imgCol{
				display: flex;
				align-items: center;
				img{
					width: 100%;
					max-width: 100%;
					position: inherit;
					top: auto;
				}
			}
		}
	}
	@include breakpoints(small){
		.overlay{
			padding: rem-calc(40 0);
		}
		.contentImgWrap{
			display: block;				
			.contentCol{
				width: 100%;
				padding: 0;
				margin-bottom: rem-calc(30);
			}
			.imgCol{
				width: 100%;
				display: block;				
			}
		}
	}
}

