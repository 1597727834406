.specializing_sec {
	padding: rem-calc(60 0 0);
	position: relative;
	overflow: hidden;
	&.grey {
		background-color: $light_grey;
	}
	.intro-text {
		text-align: center;
		max-width: rem-calc(1000);
		margin: 0 auto rem-calc(35);
	}
	.row {
		.column {
			text-align: center;
			position: relative;
			height: 100%;
			h3 {
				font-weight: $bold;
			}
			.col-content {
				max-width: rem-calc(410);
				margin: 0 auto;
				padding: rem-calc(0 15);
				min-height: rem-calc(140);
			}
			.bg_text {
				.button {
					position: absolute;
					top: rem-calc(35);
					z-index: 1;
					min-width: rem-calc(144);

				}
			}
			&.left {
				position: relative;
				.bg_text {
					.button {
						right: rem-calc(50);
					}
				}
				&:before {
					content: '';
					display: inline-block;
					width: rem-calc(1);
					border-right: solid rem-calc(1) #cacaca;
					position: absolute;
					top: 0;
					bottom: rem-calc(28);
					right: 0;
					z-index: 2;
				}
			}
			&.right {
				.bg_text {
					.button {
						left: rem-calc(50);
					}
				}
			}
			.button {
				margin-bottom: 0;
			}
		}
	}
	&.greyBg {
		.bg_text {
			span {
				color: $white;
			}
			&:before {
				/* ms ( IE 10.0+ ) */
				background: -ms-linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, rgba(238, 238, 238, 0.85) 100%);

				/* WebKit (Chrome 10.0+, safari 5.1+ )*/
				background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, rgba(238, 238, 238, 0.85) 100%);

				/* Moz ( Moz 3.6+ )*/
				background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, rgba(238, 238, 238, 0.85) 100%);

				/* Opera ( opera 11.6+ )*/
				background: -o-linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, rgba(238, 238, 238, 0.85) 100%);

				/* W3C Markup */
				background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, rgba(238, 238, 238, 0.85) 100%);
			}
		}
	}
	@include breakpoints(tablet-landscape) {
		.row {
			.column {
				.button {
					font-size: rem-calc(20);
					padding: rem-calc(10 20);
				}
			}
		}
	}
	@include breakpoints(small) {
		padding: rem-calc(40 0 0);
		.intro-text {
			margin-bottom: rem-calc(20);
		}
		.grid-x.row {
			margin: 0 -15px;
			.column {
				padding: rem-calc(30 0);
				.col-content {
					min-height: inherit;
					margin-bottom: rem-calc(20);
				}
				.bg_text {
					height: auto;
					display: block;
					span {
						position: inherit;
						bottom: auto;
						display: none;
					}
					.button {
						position: inherit;
						top: auto;
					}
				}
				&.left {
					border-right: 0;
					border-bottom: solid rem-calc(1) #cacaca;
					.bg_text {
						.button {
							right: auto;
							@include transform-translate-hor(0);
						}
					}
					&:before {
						display: none;
					}
				}
				&.right {
					.bg_text {
						.button {
							left: auto;
							@include transform-translate-hor(0);
						}
					}
				}
				.button {
					min-width: rem-calc(180);
				}
			}
		}
	}
}

