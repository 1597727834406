.credentials_awards{ 	
	padding: rem-calc(108 0 30);	
	h2{
		text-align: center;
		margin-bottom: rem-calc(55);
	}
	.logos{
		padding-top: rem-calc(15);
		img{
			display: inline-block;
			margin: rem-calc(0 60 35 0);
			&:nth-child(2n){
				margin-right: 0;
			}
		}
	}
	.credentials-row{
		display: flex;
		width: 100%;
		.credentials-left{
			width: 47%;			
			padding: rem-calc(0 15 0 0);
		}
		.credentials-right{
			width: 53%;
			padding: rem-calc(0 0 0 15);
		}
	}
	.title-list{
		li{			
			border-bottom: rem-calc(1) solid $light_blue;
			margin-bottom: 0;
			color: $blue;
			font-weight: $bold;
			padding: rem-calc(14 30 13);
			display: block;
			&:before, &:after{
				display: none;
			}			
		}
	}
	@include breakpoints(tablet-landscape-x){	
		padding: rem-calc(60 0);
		.logos{
			img{
				margin: rem-calc(0 20 20 0);
			}
		}
	}
	@include breakpoints(tablet-portrait){
		h2{
			margin-bottom: rem-calc(40);
		}
		.logos{
			text-align: center;
			img{				
				&:nth-child(2n){
					margin: rem-calc(0 20 20);
				}
			}
		}
		.credentials-row{
			display: block;
			.credentials-left{
				width: 100%;
				padding: 0;
			}
			.credentials-right{
				width: 100%;
				padding: 0;
			}
		}
	}
	@include breakpoints(small){
		padding: rem-calc(40 0);	
		h2{
			margin-bottom: rem-calc(30);
		}
		.logos{
			img{
				margin: rem-calc(0 20 20 0);
			}
		}

		.title-list{
			li{
				padding: rem-calc(10 15);				
			}
		}
	}
}

