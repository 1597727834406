.timeline_sec {
	background-color: $light_grey;
	padding: rem-calc(50 0);
	.event-content {
		text-align: center;
		max-width: rem-calc(850);
		margin: 0 auto;
		.timeline {
			position: relative;
			padding-bottom: rem-calc(48);
			&:before {
				content: '';
				display: inline-block;
				border-left: rem-calc(1) solid $white_light;
				width: rem-calc(1);
				height: rem-calc(93);
				position: relative;
				top: rem-calc(15);
			}
			li {
				padding: 0;
				&:after {
					display: none;
				}
				&:before {
					content: '';
					display: inline-block;
					border-width: rem-calc(0 0 0 1);
					border-left: rem-calc(1) solid $white_light;
					border-radius: inherit;
					width: rem-calc(1);
					height: rem-calc(48);
					top: auto;
					bottom: rem-calc(-53);
					left: 50%;
				}
				.bg_text {
					padding: 0;
				}
				.timeline-caption {
					position: relative;
					z-index: 1;
					margin-top: rem-calc(-130);
					@include breakpoints(large) {
						margin-top: rem-calc(-90);
					}
					@include breakpoints(tablet-landscape) {
						margin-top: rem-calc(-80);
					}
					@include breakpoints(small) {
						margin-top: rem-calc(-60);
					}
					@include breakpoints(x-small) {
						margin-top: rem-calc(-45);
					}
					@include breakpoints(extra-small) {
						margin-top: rem-calc(-20);
					}
				}
				.year-title {
					color: $blue;
					font-size: rem-calc(48);
					line-height: rem-calc(54);
					font-weight: $bold;
					display: block;
					margin-bottom: rem-calc(25);
				}
				p {
					color: $blue;
					font-size: rem-calc(24);
					line-height: rem-calc(36);
					margin-bottom: rem-calc(22);
				}
				&:nth-child(-n+5) {
					visibility: visible;
					animation: none;
				}
			}
		}
	}
	@include breakpoints(tablet-portrait) {
		padding: rem-calc(40 0);
		.event-content {
			.timeline {
				li {
					.year-title {
						font-size: rem-calc(38);
						line-height: rem-calc(44);
					}
					p {
						font-size: rem-calc(20);
						line-height: rem-calc(28);
					}
				}
			}
		}
	}
	@include breakpoints(small) {
		.event-content {
			.timeline {
				&:before {
					height: rem-calc(60);
				}
				li {
					p {
						font-size: rem-calc(18);
						line-height: rem-calc(26);
						margin-bottom: rem-calc(15);
					}
					.year-title {
						font-size: rem-calc(30);
						line-height: rem-calc(36);
						margin-bottom: rem-calc(10);
					}
					.timeline-caption {
						margin-top: 0;
						margin-bottom: rem-calc(50);
					}
				}
			}
		}
	}

}

