.meet_team_titan_sec {
	padding: rem-calc(80 0 60);
	.intro-text {
		text-align: center;
		max-width: rem-calc(800);
		margin: 0 auto rem-calc(75);
	}
	@include breakpoints(tablet-portrait) {
		padding: rem-calc(40 0);
		.intro-text {
			margin-bottom: rem-calc(20);
		}
	}
	.button {
		margin-bottom: 0;
	}
}

.video-content {
	max-width: rem-calc(1060);
	margin: 0 auto rem-calc(56);
	.yt-video {
		padding-bottom: 49.65%;
		position: relative;
		.responsive-embed.widescreen {
			padding: 0;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
		iframe {
			width: 100% !important;
			height: 100% !important;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
		.play-button {
			span {
				background-color: $white;
				width: rem-calc(92);
				height: rem-calc(92);
				border-radius: 100%;
				display: inline-block;
				position: absolute;
				top: 50%;
				left: 50%;
				@include transform-translate(-50%, -50%);
				z-index: 2;
				@include box-shadow(0, 0, 35px, 0, rgba(0, 0, 0, 0.35));
				&:before {
					content: '';
					display: inline-block;
					width: 0;
					height: 0;
					border-top: rem-calc(7) solid transparent;
					border-bottom: rem-calc(7) solid transparent;
					border-left: rem-calc(7) solid $blue;
					position: absolute;
					top: 50%;
					left: 50%;
					@include transform-translate(-50%, -50%);
				}
			}
			&:after {
				content: '';
				display: inline-block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: transparent;
				z-index: 1;
			}
		}
		&:hover {
			.play-icon {
				&:before {
					background-color: $blue;
					border-left-color: $white;
				}
			}
		}
	}
	@include breakpoints(tablet-portrait) {
		margin-bottom: rem-calc(30);
		.yt-video {
			.play-button {
				span {
					width: rem-calc(60);
					height: rem-calc(60);
				}
			}
		}
	}
	@include breakpoints(small) {
		margin-bottom: rem-calc(15);
		.yt-video {
			.play-button {
				span {
					width: rem-calc(42);
					height: rem-calc(42);
					&:before {
						border-top-width: rem-calc(5);
						border-bottom-width: rem-calc(5);
						border-left-width: rem-calc(5);
					}
				}
			}
		}
	}
}

