.meet_titan_bot_sec {
	background-color: $blue;
	padding: rem-calc(70 0 110);
	position: relative;
	overflow: hidden;
	.grid-container {
		position: relative;
		z-index: 1;
	}
	h2 {
		color: $white;
	}
	.intro-text {
		text-align: center;
		color: $white;
		max-width: rem-calc(800);
		margin: 0 auto rem-calc(75);
		p {
			color: $white;
			font-size: rem-calc(20);
			line-height: rem-calc(30);
		}
	}
	.bg_text {
		position: absolute;
		top: auto;
		bottom: 0;
		right: 0;
		left: 0;
	}
	.row {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		p {
			font-size: rem-calc(18);
			line-height: rem-calc(30);
		}
		.white-button {
			color: $white;
			border-color: $white;
			margin-top: rem-calc(25);
			.arrow-right {
				border-left-color: $white;
			}
			&:hover, &:focus {
				background-color: $white;
				color: $blue;
				.arrow-right {
					border-left-color: $blue;
				}
			}
		}
		.videoImg {
			background-size: 100%;
			background-repeat: no-repeat;
			background-position: center center;
			padding-bottom: 60%;
			position: relative;
			max-width: rem-calc(1034);
			margin: 0 auto;
			.button {
				position: absolute;
				top: 50%;
				left: 50%;
				@include transform-translate(-50%, -50%);
				z-index: 2;
				margin-bottom: 0;
			}
			&:hover {
				.video-button {
					background-color: $white;
					color: $blue;
					border-color: $blue;
					.arrow-right {
						border-left-color: $blue;
					}
				}
			}
		}
		.video-content {
			.yt-video {
				padding-bottom: 56.65%;
				iframe {
					width: 100% !important;
					height: 100% !important;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					border: none;
				}
				.button {
					position: absolute;
					top: 50%;
					left: 50%;
					@include transform-translate(-50%, -50%);
					z-index: 2;
					margin-bottom: 0;
				}
				&:hover {
					.video-button {
						background-color: $white;
						color: $blue;
						border-color: $blue;
						.arrow-right {
							border-left-color: $blue;
						}
					}
				}
			}
		}
		.leftCol {
			.leftColInner {
				max-width: rem-calc(420);
				padding: rem-calc(0 15);
				margin: 0 auto;
			}
		}
	}
	@include breakpoints(tablet-portrait) {
		padding: rem-calc(50 0 110);
	}
	@include breakpoints(small) {
		padding: rem-calc(50 0);
		.intro-text {
			margin-bottom: rem-calc(20);
		}
		.row {
			.videoImg {
				.button {
					.arrow-right {
						margin-left: 0;
					}
				}
			}
			.white-button {
				margin-top: 0;
			}
			.leftCol {
				margin-bottom: rem-calc(30);
				.leftColInner {
					max-width: inherit;
				}
			}
		}
	}
	@include breakpoints(x-small) {
		padding: rem-calc(50 0 70);
	}
}

