header.site-header {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	background-color: $white;
	z-index: 999;
	@include transition(.5s, all, ease);
	text-align: center;
	/*max-width: rem-calc(1680);*/
	margin: 0 auto;
	.main-header {
		height: rem-calc(92);
		@include breakpoints(tablet-portrait){
			height: rem-calc(60);
		}
		.grid-container{
			@include flex;
			align-items: center;
			height: 100%;
		}
		.logo {
			max-width: rem-calc(192);
			a{
				display: block;
				img{
					height: 100%;
					max-height: rem-calc(65);
				}
			}
			@include breakpoints(tablet-portrait){
				max-width: rem-calc(150);
			}
		}
		.main_nav{
			display: flex;
			height: 100%;
			display: inline-block;
			vertical-align: top;
			margin-left: auto;
			text-align: right;
			ul{
				justify-content: center;
				li{
					list-style: none;
					display: inline-block;
					vertical-align: top;
					position: relative;
					padding: 0;
					&:before, &:after{
						display: none;
					}
					a{
						font-family: $proxima_nova;
						font-weight: $extrabold;
						padding: rem-calc(35 30 36);
						font-size: rem-calc(16);
						line-height: rem-calc(21);
						text-transform: uppercase;
						color: $blue;
						letter-spacing: rem-calc(0.45);
						&:hover{
							opacity: 0.65;
						}
					}
					.sub-menu{
						position: absolute;
						top: 100%;
						left: 0;
						width: rem-calc(263);
						background-color: $orange;
						margin: 0;
						opacity: 0;
						right: 0;
						margin: auto;
						visibility: hidden;
						padding: rem-calc(10 0);
						@include transition(.5s, all, ease);
						@include box-shadow(0px, 0px, 13px, -4px, $black_color);
						display: none;
						li{
							display: block;
							width: 100%;
							padding: 0;
							a{
								text-align: left;
								font-family: $proxima_nova;
								font-weight: $bold;
								font-size: rem-calc(16);
								line-height: rem-calc(21);
								color: $white;
								text-transform: none;
								padding: rem-calc(15 30);
								border-bottom: rem-calc(1) solid $orange;
								&:hover{
									opacity: 0.65;
								}
							}
							&:last-child{
								a{
									border-bottom: 0;
								}
							}
							&.current-menu-item{
								a{
									opacity: 0.65;
								}
							}
							ul{
								top: 0;
								left: 100%;
							}
						}
					}
					&:hover{
						background-color: transparent;
						.sub-menu{

							opacity: 1;
							visibility: visible;
						}
					}
					&.is-active{
						> a{
							opacity: 1;
							color: $orange;
						}
					}
					&.right{
						.sub-menu{
							left: auto;
							right: 0;
							li{
								ul{
									left: auto;
									right: 100%;
								}
							}
						}
					}
					&.contact-button{
						padding: rem-calc(21 0);
						margin-left: rem-calc(50);
						margin-right: rem-calc(7);
						a{
							background-image: url(../images/button-right-arrow-hover.png);
							background-position: 85% 48%;
							background-repeat: no-repeat;
							background-color: $white;
							font-weight: $black;
							font-size: rem-calc(16);
							color: $orange;
							line-height: rem-calc(24);
							letter-spacing: rem-calc(0.5);
							display: inline-block;
							border: rem-calc(1) solid $orange;
							border-radius: rem-calc(30);
							padding: rem-calc(12 42 12 31);
							text-decoration: none;
							&:hover, &:focus{
								background-image: url(../images/button-right-arrow.png);
								background-color: $orange;
								color: $white;
								border-color: $orange;
								opacity: 1;
							}
						}
						@include breakpoints(large){
							margin-left: rem-calc(50);
						}
						@include breakpoints(tablet-landscape){
							margin-left: rem-calc(30);
						}
						@include breakpoints(tablet-portrait){
							margin-left: 0;
						}
					}
				}
			}
		}
		/*.header-right{
		.button{
		margin-bottom: 0;
		text-transform: uppercase;
		padding: rem-calc(8 30);
	}
		@include breakpoints(large){
		.button{
		padding: rem-calc(12 15);
	}
	}
		@include breakpoints(tablet-portrait){
		display: none;
	}
	}	*/
	}
	@include breakpoints(max-x-large){
		.main-header {
			.main_nav{
				ul{
					li{
						a{
							padding: rem-calc(35 20 36);
						}
					}
				}
			}
		}
	}
	@include breakpoints(large){
		.main-header {
			.logo {
				max-width: rem-calc(160);
			}
			.main_nav{
				ul{
					li{
						a{
							font-size: rem-calc(15);
							padding: rem-calc(35 12 36);
						}
					}
				}
			}
		}
	}
	@include breakpoints(tablet-portrait){
		.main-header{
			.logo {
				max-width: rem-calc(120);
			}
			.main_nav{
				ul{
					padding: rem-calc(60 50 40);
					li{
						text-align: left;
						&.contact-button{
							a{
								display: inline-block;
								background-color: $white;
								color: $orange;
								&:hover, &:focus{
									background-color: $orange;
									color: $white;
									border-color: $white;
								}
							}
						}
						&.is-active{
							a{
								color: $blue;
							}
						}
						a{
							font-size: rem-calc(16);
							color: $white;
							padding: rem-calc(20 0);
							float: left;
							&:hover{
								opacity: 0.8;
							}
						}
						.sub-menu{
							position: inherit;
							top: auto !important;
							left: auto !important;
							right: auto !important;
							opacity: 1;
							visibility: visible;
							box-shadow: none;
							width: 100%;
							padding: rem-calc(0 0 0 15);
							float: left;
							transition: inherit;
							display: block;
							li{
								display: inline-block;
								a{
									padding: rem-calc(15 0) !important;;
									&:hover{
										opacity: 0.8;
									}
								}
								&.current_page_item{
									a{
										color: $blue;
										opacity: 1;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	@include breakpoints(small){
		.main-header{
			.main_nav{
				ul{
					padding: rem-calc(50 15);
				}
			}
		}
	}
}

.fixed_header header.site-header {
	width: 100%;
	position: fixed;
	z-index: 999;
	-webkit-animation: slideDown 0.5s linear 0s;
	animation: slideDown 0.5s linear 0s;
	backface-visibility: hidden;
	@include box-shadow(0px, 0px, 13px, -4px, $black_color);
}



/*
Only required is to display none the .menu-icon
*/


body.menu-open { overflow: hidden; }


body.slidemenuRight .enumenu_ul .contact-info { display: none; }
@keyframes slideDown {
	0% {
		transform: translateY(-100%)
	}
	100% {
		transform: translateY(0%)
	}
}
@-webkit-keyframes slideDown {
	0% {
		-webkit-transform: translateY(-100%)
	}
	100% {
		-webkit-transform: translateY(0%)
	}
}
@-moz-keyframes slideDown {
	0% {
		-moz-transform: translateY(-100%)
	}
	100% {
		-moz-transform: translateY(0%)
	}
}



.menu-icon {
	display: none;
	cursor: pointer;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	font-weight: bold;
	line-height: 33px;
	font-size: 0;
	/*padding: 10px 0;*/
	width: 50px;
	height: 34px;
	position: absolute;
	right: 15px;
	z-index: 1;
	top: 50%;
	transform: translateY(-50%);
}
.menu-icon::after {
	display: none;
}
.menu-icon .menu-box {
	width: 50px;
	height: 34px;
	margin-right: 20px;
	padding: 5px;
	position: absolute;
	left: 0;
	z-index: 1;
}
.menu-icon .menu-box span {
	width: 100%;
	height: 4px;
	margin-bottom: 6px;
	background: #3155a4;
	display: block;
	border-radius: 2px;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	&:last-child{
		margin-bottom: 0;
	}
}
.menu-icon.active .menu-box span{
	background: #ffffff;
}
.menu-icon.active span:nth-child(1) {
	transform: rotate(45deg) translateY(8px) translateX(6px);
	-webkit-transform: rotate(45deg) translateY(8px) translateX(6px);
}
/*.menu-icon.hover:not(.active) span:nth-child(1) {
transform: rotate(90deg) translateX(8px);
-webkit-transform: rotate(90deg) translateX(8px);
}*/
.menu-icon.active span:nth-child(2) {
	opacity: 0;
}
.menu-icon.active span:nth-child(3) {
	transform: rotate(-45deg) translateY(-7px) translateX(6px);
	-webkit-transform: rotate(-45deg) translateY(-7px) translateX(6px);
}
.enumenu_ul .caret {
	color: #fff;
	margin-left: 7px;
}
.enumenu_ul {
	padding: 0px;
	list-style: none;
}
.enumenu_ul > li {
	float: left;
	vertical-align: top;
	padding-bottom: 23px;
	position: relative;
	padding-bottom: 0;
	padding-left: 0;
}
.enumenu_ul > li:before{
	content: none;
}


.menu .is-active > a { background-color: transparent; color: #555559; }


.enumenu_ul li a {
	font-family: $proxima_nova;
	font-size: 17px;
	font-weight: $light;
	color: #555559;
	padding: 36px 15px;
	text-transform: uppercase;
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
}
.enumenu_ul > li:hover > a,
.enumenu_ul > li:focus > a,
.enumenu_ul > li.active > a {
	color: #2d54a4;
}
.enumenu_ul > li ul {
	display: none
}
.enumenu_ul ul li {
	width: 100%;
	margin: 0;
	list-style: none;
	position: relative;
}
.enumenu_ul.desk ul {
	top: 100%;
	z-index: 999;
	list-style: none;
	left: 0px;
	background: #000000;
	position: absolute;
	min-width: 150px;
}
/*Sub menu styles*/

.menu.submenu-ul-level1 li a {
	background: rgba(31, 26, 107, 0.8);
	color: #fff;
	padding-left: 90px;
}
.enumenu_ul.desk li:hover > ul {
	display: block;
	opacity: 1;
}
.enumenu_ul.desk .sb-menu .sb-menu {
	left: 100%;
	top: 0;
}
.enumenu_ul.desk ul li a {
	padding: 10px 10px;
	display: block;
	color: #fff;
	font-size: 16px;
	text-align: center
}
.enumenu_ul .arrow {
	cursor: pointer;
	display: none;
	width: 0;
	height: 0;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-top: 12px solid #fff;
	margin: 24px 0 0 15px;
	transition: 0.5s all ease;
	@include breakpoints(tablet-portrait){
		display: inline-block;
	}
}
.enumenu_ul .arrow.up {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}
.enumenu_ul .sub-menu .arrow { margin-top: 17px; }

@media (max-width: 991px) {
	header {
		padding-top: 0;
	}
	.enumenu_ul {
		display: none;
		padding: 60px 0 30px;
	}
	.menu-block {
		height: auto;
	}
	.menu-icon {
		display: block
	}
	.services-detail a.pba-detail {
		border-bottom: 2px solid #d3d9da;
	}
	.enumenu_ul > li {
		width: 100%;
		margin: 0px;
		padding: 0px;
		&:first-child{
			border-top: none;
		}
	}
	.enumenu_ul > li > a {
		display: block;
		text-align: left;
		padding: 15px 15px
	}
	.enumenu_ul {
		width: 100%;
	}
	.enumenu_ul > li ul {
		background: #D1D1D1;
	}
	.enumenu_ul > li ul a {
		display: block;
		color: #000;
		padding: 5px 0;
	}
	/* Push Menu */
	body.menuslide_push {
		position: relative;
	}
	body.menuslide_push.slidemenuLeft {
		left: 0;
		transition: left 0.5s;
		-webkit-transition: left 0.5s;
		-moz-transition: left 0.5s;
		-ms-transition: left 0.5s;
		-o-transition: left 0.5s;
	}
	body.menuslide_push.slidemenuRight {
		right: 0;
		transition: right 0.5s;
		-webkit-transition: right 0.5s;
		-moz-transition: right 0.5s;
		-ms-transition: right 0.5s;
		-o-transition: right 0.5s;
	}
	body.slidemenuRight .enumenu_ul {
		right: -400px;
	}
	body.slidemenuRight.menu-open .enumenu_ul {
		right: 0;
	}
	body.slidemenuLeft .enumenu_ul {
		left: -400px;
	}
	body.slidemenuLeft.menu-open .enumenu_ul {
		left: 0;
	}
	body.menuslide_push.slidemenuLeft.menu-open {
		left: 400px
	}
	body.menuslide_push.slidemenuRight.menu-open {
		right: 400px
	}
	body.menuslide_push .enumenu_ul,
	body.menuOverlap .enumenu_ul {
		background: $orange;
		display: block;
		height: 100%;
		max-height: 100%;
		width: 100%;
		overflow: auto;
		position: fixed;
		transition: all 0.5s ease 0s;
		max-width: 400px;
		top: 0;
	}
}

@media (max-width: 480px) {
	body.menuOverlap .enumenu_ul {
		max-width: 100%;
		right: -100%;
	}
}


