.current_opportunities_sec{
	font-family: $proxima_nova;
	padding: rem-calc(70 0 0);
	position: relative;
	overflow: hidden;
	h2{
		color: $blue;
		text-align: center;
	}
	.opportunities-inner{
		max-width: rem-calc(1000);
		margin: rem-calc(0) auto;
	}
	.opportunities-list{
		margin-bottom: rem-calc(88);
		li{
			padding: rem-calc(70 250 30 0);
			border-bottom: solid rem-calc(1) $blue_grey;
			position: relative;
			margin-bottom: 0;
			&:before, &:after{
				display: none;
			}
			h3{
				font-weight: $bold;
			}
			.apply-link{
				font-size: rem-calc(16);
				color: $blue;
				text-decoration: none;
				font-weight: $black;
			}
			.button{
				position: absolute;
				bottom: rem-calc(50);
				right: rem-calc(15);
				min-width: rem-calc(140);
				padding: rem-calc(10 15);
				&:hover, &:focus{
					background-color: transparent;
					color: $blue;
				}
			}
			.para-content{
				display: none;
			}
			.para-more{
				cursor: pointer;
				z-index: 11;
			}
		}
	}
	@include breakpoints(tablet-portrait){
		padding: rem-calc(40 0 0);
		.opportunities-list{
			margin-bottom: rem-calc(50);
			li{
				padding: rem-calc(50 200 50 0);
			}
		}
	}
	@include breakpoints(small){
		.opportunities-list{
			margin-bottom: rem-calc(30);
			li{
				padding: rem-calc(30 0 80);
				.button{
					bottom: 0;
					margin-bottom: rem-calc(30);
				}
			}
		}
	}
}

