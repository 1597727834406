.titan_benefits_sec {
	padding: rem-calc(50 0 0);
	position: relative;
	overflow: hidden;
	.intro-text {
		margin-bottom: rem-calc(50);
	}
	.bg_text {
		span {
			color: $white;
			text-shadow: 0 0 75px rgba(0, 0, 0, 0.1);
		}
		&:before {
			height: auto;
			bottom: rem-calc(-30);
		}
	}
	.titan-benefits-content {
		max-width: rem-calc(800);
		margin: 0 auto rem-calc(50);
	}
	.benefits-list {
		max-width: rem-calc(500);
		margin: 0 auto rem-calc(50);
		li {
			display: flex;
			align-items: center;
			font-weight: $bold;
			color: $dark_grey;
			padding: rem-calc(12 15 12 0);
			position: relative;
			border-bottom: solid rem-calc(1) $grey_border;
			margin-bottom: 0;
			&:before, &:after {
				display: none;
			}
			.imgIcon {
				display: flex;
				align-items: center;
				justify-content: center;
				width: rem-calc(80);
				min-height: rem-calc(44);
				vertical-align: middle;
				img {
					width: auto;
				}
			}
		}
	}
	@include breakpoints(small) {
		.intro-text {
			margin-bottom: rem-calc(30);
		}
		.benefits-list {
			margin-bottom: rem-calc(30);
			li {
				padding: rem-calc(10 15 10 0);
				.imgIcon {
					min-width: rem-calc(100);
				}
			}
		}
	}
}

