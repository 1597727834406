.why_paid_media_sec {
	background-color: $blue;
	padding: rem-calc(53 0 58);
	h2 {
		color: $white;
	}
	.intro-text {
		margin-bottom: rem-calc(50);
	}
	.white-button {
		color: $white;
		border-color: $white;
		.arrow-right {
			border-left-color: $white;
		}
		&:hover, &:focus {
			background-color: $white;
			color: $blue;
			.arrow-right {
				border-left-color: $blue;
			}
		}
	}
	.media-row {
		max-width: rem-calc(1090);
		margin: 0 auto;
		.media-col {
			border-top: solid rem-calc(1) $white;
			min-height: rem-calc(405);
			padding: rem-calc(60 0);
			.media-content {
				padding: rem-calc(10 30 77);
				margin-right: auto;
				position: relative;
				height: 100%;
				.logo-img {
					display: inline-block;
					margin: rem-calc(0 50 38 0);
					&:nth-child(2) {
						margin-right: 0;
					}
				}
				.button {
					position: absolute;
					bottom: 0;
					margin-bottom: 0;
					min-width: rem-calc(185);
				}
			}
			&:nth-child(odd) {
				border-right: solid rem-calc(1) $white;
				.media-content {
					max-width: rem-calc(455);
				}
			}
			&:nth-child(even) {
				.media-content {
					max-width: rem-calc(455);
					margin-right: inherit;
					margin-left: auto;
				}
			}
			&:nth-child(1), &:nth-child(2) {
				border-top: none;
				padding-top: 0;
			}
			@include breakpoints(min-tablet) {
				&:nth-last-of-type(1), &:nth-last-of-type(2) {
					padding-bottom: 0;
				}
			}
		}
	}
	@include breakpoints(tablet-landscape-x) {
		.media-row {
			.media-col {
				padding: rem-calc(40 0);
				.media-content {
					.logo-img {
						margin: rem-calc(0 30 30 0);
					}
				}
			}
		}
	}
	@include breakpoints(tablet-landscape) {
		.media-row {
			.media-col {
				padding: rem-calc(30 0);
				&:nth-child(odd) {
					.media-content {
						max-width: inherit;
					}
				}
			}
		}
	}
	@include breakpoints(tablet-portrait) {
		padding: rem-calc(60 0);
		.media-row {
			.media-col {
				.media-content {
					.logo-img {
						max-width: rem-calc(120);
					}
				}
			}
		}
	}
	@include breakpoints(small) {
		padding: rem-calc(50 0 30);
		.media-row {
			.media-col {
				min-height: inherit;
				.media-content {
					padding: rem-calc(0 15 62);
					.logo-img {
						max-width: rem-calc(100);
						margin: rem-calc(0 15 15 0);
					}
				}
				&:nth-child(odd) {
					border-right: none;
				}
				&:nth-child(even) {
					.media-content {
						max-width: inherit;
					}
				}
				&:nth-child(1), &:nth-child(2) {
					border-top: solid rem-calc(1) $white;
					padding: rem-calc(30 0);
				}
				&:first-child {
					border-top: 0;
					padding-top: 0;
				}
			}
		}
	}
}

