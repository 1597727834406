.footer_cta{
	padding: rem-calc(50 0 80);
	.intro-text{
		text-align: center;
		max-width: rem-calc(800);
		margin: 0 auto rem-calc(40);
	}
	.cta_row{
		position: relative;
	}
	.footer-left-col{
		padding-left: rem-calc(15);
		ul{
			margin-top: rem-calc(10);
			margin-bottom: rem-calc(35);
			li{
				font-weight: $bold;
				color: $dark_grey;
				line-height: rem-calc(34);
				border-bottom: solid rem-calc(1) $bd_color;
				padding: rem-calc(20 0 20 80);
				display: block;
				position: relative;
				margin-bottom: 0;
				&:before, &:after {
					display: none;
				}
				p{
					font-weight: $bold;
					color: $dark_grey;
					line-height: rem-calc(34);
					margin-bottom: 0;
				}
				.icon{
					@extend .bgimage;
					background-size: 100% !important;
					display: inline-block;
					max-width: rem-calc(60);
					width: rem-calc(38);
					height: 100%;
					vertical-align: middle;
					position: absolute;
					top: 50%;
					left: rem-calc(11);
					@include transform-translate-ver(-50%);
				}

			}
		}
		.lg-button{
			min-width: rem-calc(265);
			padding: rem-calc(24 30);
			border-radius: rem-calc(50);
		}
	}
	.footer-right-col{
		width: 50%;
		position: absolute;
		top: 0;
		left: 50%;
		padding: rem-calc(0 15);
		.desk-img{
			max-width: 90%;
			margin-top: rem-calc(-90);
		}
	}
	@include breakpoints(extra-large){
		.footer-right-col{
			.desk-img{
				max-width: 100%;
			}
		}
	}
	@include breakpoints(large){
		.footer-right-col{
			.desk-img{
				width: 100%;
				max-width: rem-calc(600);
				margin-top: rem-calc(-50);
			}
		}
	}
	@include breakpoints(tablet-landscape){
		padding: rem-calc(40 0);
		.footer-left-col{
			margin-bottom: rem-calc(30);
			padding-left: 0;
			.lg-button{
				min-width: rem-calc(160);
				padding: rem-calc(10 15);
			}
		}
		.footer-right-col{
			width: 100%;
			position: inherit;
			top: auto;
			left: auto;
			.desk-img{
				margin-top: 0;
			}
		}
	}
}

.footer{
	background-color: $blue;
	padding: rem-calc(25 0);
	.footer-row{
		@include flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;
		.menu-footer-container{
			width: 100%;
		}
		.menu {
			@include flex;
			flex-wrap: wrap;
			width: 100%;
			justify-content: space-between;
			li{
				padding: 0;
				&:before, &:after {
					display: none;
				}
			}
			> li{
				> a{
					font-size: rem-calc(24);
					line-height: rem-calc(30);
					color: $white;
					font-weight: $bold;
					padding: 0;
					margin-bottom: rem-calc(30);
				}
				.sub-menu{
					display: none;
					li{
						padding: 0;
						margin-bottom: rem-calc(22);
						a{
							font-size: rem-calc(16);
							text-decoration: none;
							font-weight: $bold;
							color: $primary_color;
							padding: 0;
							margin-bottom: 0;
							display: inline-block;
							&:hover, &:focus{
								color: $white;
							}
						}
					}
				}
			}
			@include breakpoints(small){
				> li{
					width: 33.333%;
					> a{
						font-size: rem-calc(20);
						line-height: rem-calc(26);
					}
				}
			}
			@include breakpoints(x-small){
				> li{
					width: 100%;
					margin-bottom: rem-calc(10);
					text-align: center;
				}
			}
			@include breakpoints(extra-small){
				> li{
					width: 100%;

				}
			}
		}
		.contact-form{
			width: 100%;
			height: auto;
			h3{
				font-family: $proxima_nova;
				color: $white;
				font-size: rem-calc(24);
				line-height: rem-calc(30);
				@include breakpoints(small){
					font-size: rem-calc(20);
					line-height: rem-calc(26);
				}
			}
			input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				opacity: 1;
				color: $dark_grey;
			}
			input::-moz-placeholder { /* Firefox 19+ */
				opacity: 1;
				color: $dark_grey;
			}
			input:-ms-input-placeholder { /* IE 10+ */
				opacity: 1;
				color: $dark_grey;
			}
			input:-moz-placeholder { /* Firefox 18- */
				opacity: 1;
				color: $dark_grey;
			}
			.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.ginput_container,
			.gform_wrapper li.gfield.gfield_error.gfield_contains_required label.gfield_label {
				margin-top: 0;
			}
			.gform_wrapper ul.gform_fields{
				@include flex;
				margin-left: -15px !important;
				margin-right: -15px !important;
				li.gfield{
					width: 33.333%;
					padding: rem-calc(0 15);

				}
				@include breakpoints(small){
					margin-left: -10px !important;
					margin-right: -10px !important;
				}
				@include breakpoints(x-small){
					display: block;
					margin-left: 0 !important;
					margin-right: 0 !important;
					li.gfield{
						width: 100%;
						padding: 0;
					}
				}
			}
			.gform_footer{
				margin-top: 0;
				padding-top: 15px;
				text-align: right;
				input.button, input[type=submit]{
					background-image: url(../images/button-right-arrow-red-hover.png);
					background-color: $white;
					color: $orange;
					background-position: 85% center;
					background-repeat: no-repeat;
					font-size: rem-calc(18);
					line-height: 1.2 !important;
					height: rem-calc(45);
					border-color: $orange;
					padding: rem-calc(10 40 10 30);
					border-radius: 20px;
					min-width: inherit;
					&:hover, &:focus{			 			
						background-color: $orange;
						background-image: url(../images/button-right-arrow.png); 
						border-color: $orange; 
						outline: none;
						color: $white;
					}
					@include breakpoints(small){
						height: rem-calc(45);
						margin-bottom: 0;
						width: auto;
					}
				}
			}
		}
		@include breakpoints(x-small){
			.contact-form{
				width: 100%;
				h3{
					text-align: center;
				}
				.gform_wrapper{
					.gform_heading h3.gform_title{
						text-align: center;
					}
				}
				.gform_footer{
					text-align: center;
				}
			}
		}
	}
	.footer-bottom{
		font-family: $din;
		/*margin-top: rem-calc(-82);*/
		.footer_text{
			font-size: rem-calc(16);
			color: $med_grey;
			margin-bottom: rem-calc(24);
			.copyright_text{
				display: inline-block;
				vertical-align: middle;
			}
			.address{
				display: inline-block;
				a{
					color: $med_grey;
					&:hover, &:focus{
						color: $white;
					}
				}
			}
			span{
				font-family: $din;
				display: inline-block;
			}
			.divider{
				font-size: 0;
				border-left: rem-calc(1) solid $med_grey;
				width: rem-calc(1);
				height: rem-calc(12);
				vertical-align: middle;
				margin: rem-calc(0 10);
			}

		}

		@include breakpoints(x-small){
			text-align: center;
			.footer_text{
				.divider{
					display: none;
				}
			}
		}
		@include breakpoints(tablet-landscape){
			margin-top: 0;
		}
	}
}



.social-icons{
	li{
		display: inline-block;
		padding: 0;
		margin: rem-calc(0 18);
		&:before, &:after {
			display: none;
		}
		&:first-child{
			margin-left: 0;
		}
		a{
			background-color: $green;
			width: rem-calc(40);
			height: rem-calc(40);
			line-height: rem-calc(40);
			display: inline-block;
			border-radius: 100%;
			text-align: center;
		}
	}
	@include breakpoints(small){
		li{
			margin: rem-calc(0 7);
		}
	}
}
