.contact-sec{
	position: relative;
	.bg_text{
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 3;
		span{
			color: $white;
			text-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
		}
		&:before{
			height: auto;
			/*bottom: rem-calc(-150);*/
		}
	}

	@include breakpoints(tablet-landscape){
		.bg_text{
			position: relative;
			bottom: auto;
			left: auto;
			right: auto;
		}
	}
	&.gated-content-sec{
		&:before{
			content: '';
			display: inline-block;
			width: 100%;
			height: rem-calc(420);
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			background:-ms-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(237,237,237,1) 40.74%, rgba(237,237,237,1) 100% );
			background:-webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(237,237,237,1) 40.74%, rgba(237,237,237,1) 100% );
			background:-moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(237,237,237,1) 40.74%, rgba(237,237,237,1) 100% );
			background:-o-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(237,237,237,1) 40.74%, rgba(237,237,237,1) 100% );
			background:linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(237,237,237,1) 40.74%, rgba(237,237,237,1) 100% );
			z-index: 2;
		}
		.contact-middel{
			padding: rem-calc(50 0);
		}
		.contact-middel .contact-row .contact-form .contact-img-wrap{
			min-height: rem-calc(76);
		}
		.contact-middel .contact-row .contact-form .contact-img-wrap img {
			left: 0;
			width: auto;
			max-width: 100%;
		}
	}
	&.free-competitive-sec{
		&:before{
			content: '';
			display: inline-block;
			width: 100%;
			height: rem-calc(420);
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			background:-ms-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(237,237,237,1) 40.74%, rgba(237,237,237,1) 100% );
			background:-webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(237,237,237,1) 40.74%, rgba(237,237,237,1) 100% );
			background:-moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(237,237,237,1) 40.74%, rgba(237,237,237,1) 100% );
			background:-o-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(237,237,237,1) 40.74%, rgba(237,237,237,1) 100% );
			background:linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(237,237,237,1) 40.74%, rgba(237,237,237,1) 100% );
			z-index: 2;
		}
		.contact-middel{
			padding: rem-calc(50 0);
		}
	}
}
.contact_banner{
	@extend .bgimage;
	position: relative;
	padding: rem-calc(35 0);
	&:before{
		content: '';
		display: inline-block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		/* ms ( IE 10.0+ ) */
		background:-ms-linear-gradient(180deg, rgba(0,13,73,0.80) 0%, rgba(0,13,73,0)  );

		/* WebKit (Chrome 10.0+, safari 5.1+ )*/
		background:-webkit-linear-gradient(180deg, rgba(0,13,73,0.80) 0%, rgba(0,13,73,0) 100% );

		/* Moz ( Moz 3.6+ )*/
		background:-moz-linear-gradient(180deg, rgba(0,13,73,0.80) 0%, rgba(0,13,73,0) 100% );

		/* Opera ( opera 11.6+ )*/
		background:-o-linear-gradient(180deg, rgba(0,13,73,0.80) 0%, rgba(0,13,73,0) 100% );

		/* W3C Markup */
		background:linear-gradient(180deg, rgba(0,13,73,0.80) 0%, rgba(0,13,73,0) 100% );
	}
	.contact_banner_content{
		width: 100%;
		min-height: rem-calc(393);
		height: 100%;
		max-width: 50%;
		margin-right: auto;
		display: flex;
		align-items: center;
		position: relative;
		z-index: 1;
		p{
			color: $white;
			display: block;
		}
		.page-title{
			font-size: rem-calc(48);
			line-height: rem-calc(54);
			color: $white;
			font-weight: $bold;
		}
		h2{
			color: $white;
			font-size: rem-calc(48);
			line-height: rem-calc(54);
		}
		h4{
			color: $white;
			font-size: rem-calc(16);
			font-weight: $bold;
			line-height: rem-calc(22);
			text-transform: uppercase;
			letter-spacing: rem-calc(2);
			margin-bottom: rem-calc(30);
		}
	}
	@include breakpoints(tablet-landscape){
		.contact_banner_content{
			max-width: inherit;
			min-height: rem-calc(300);
			padding: rem-calc(30 0);
			.page-title{
				font-size: rem-calc(38);
				line-height: rem-calc(44);
			}
		}
	}
	@include breakpoints(small){
		.contact_banner_content{
			.page-title{
				font-size: rem-calc(30);
				line-height: rem-calc(36);
			}
		}
	}
}

.contact-middel{
	.title-text{
		color: $blue;
		font-weight: $bold;
	}
	.contact-row{
		@include flex;
		.contact-left-column{
			width: 55%;
			height: 100%;
			padding: rem-calc(0 165 125 0);
			position: relative;
			z-index: 3;
			h3{
				font-size: rem-calc(20);
				line-height: rem-calc(28);
				color: $blue;
				font-weight: $bold;
				margin-bottom: rem-calc(40);
			}
			.button{
				font-size: rem-calc(24);
				line-height: rem-calc(50);
				height: rem-calc(80);
				padding: rem-calc(15 30);
				border-radius: 40px;
				text-align: center;
				min-width: rem-calc(265);
			}
			@include breakpoints(large){
				padding-right: rem-calc(115);
			}
			@include breakpoints(tablet-landscape){
				padding: rem-calc(0 0 30);
				width: 100%;
			}
			@include breakpoints(small){
				.button{
					font-size: rem-calc(18);
					line-height: rem-calc(24);
					height: auto;
					padding: rem-calc(10 15);
					min-width: rem-calc(184);
				}
			}
		}
		.contact-right-column{
			width: 50%;
			height: 100%;
			padding: rem-calc(150 50 220 108);
			.address{
				font-family: $proxima_nova;
				font-size: rem-calc(20);
				line-height: rem-calc(30);
				color: $med_grey;
				ul{
					li{
						display: block;
						margin-bottom: rem-calc(30);
						padding: 0;
						&:before, &:after{
							display: none;
						}
						a{
							font-weight: $bold;
							text-decoration: none;
						}
					}
				}
			}
			@include breakpoints(tablet-landscape){
				width: auto;
				padding: rem-calc(50 15 30);
			}
			@include breakpoints(small){
				padding-left: 0;
				padding-right: 0;
				.address{
					ul{
						li{
							margin-bottom: rem-calc(15);
						}
					}
				}
			}
		}
		.contact-form{
			width: 45%;
			/* ms ( IE 10.0+ ) */
			background:-ms-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(236,236,236,1) 20%, rgba(236,236,236,1) 100% );
			/* WebKit (Chrome 10.0+, safari 5.1+ )*/
			background:-webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, #ececec 20%, #ececec 100% );
			/* Moz ( Moz 3.6+ )*/
			background:-moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, #ececec 20%, #ececec 100% );
			/* Opera ( opera 11.6+ )*/
			background:-o-linear-gradient(180deg, rgba(255,255,255,0) 0%, #ececec 20%, #ececec 100% );
			/* W3C Markup */
			background:linear-gradient(180deg, rgba(255,255,255,0) 0%, #ececec 20%, #ececec 100% );
			padding-left: rem-calc(50);
			padding-bottom: rem-calc(180);
			&:before{
				content: '';
				display: inline-block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 100%;
				z-index: 1;
				/* ms ( IE 10.0+ ) */
				background:-ms-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(236,236,236,1) 20%, rgba(236,236,236,1) 100% );
				/* WebKit (Chrome 10.0+, safari 5.1+ )*/
				background:-webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, #ececec 20%, #ececec 100% );
				/* Moz ( Moz 3.6+ )*/
				background:-moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, #ececec 20%, #ececec 100% );
				/* Opera ( opera 11.6+ )*/
				background:-o-linear-gradient(180deg, rgba(255,255,255,0) 0%, #ececec 20%, #ececec 100% );
				/* W3C Markup */
				background:linear-gradient(180deg, rgba(255,255,255,0) 0%, #ececec 20%, #ececec 100% );
			}
			.contact-img-wrap{
				position: relative;
				min-height: rem-calc(285);
				z-index: 1;
				margin-bottom: rem-calc(30);
				img{
					position: absolute;
					bottom: rem-calc(15);
					left: rem-calc(-170);
					max-width: rem-calc(807);
					width: inherit;
				}
				@include breakpoints(large){
					img{
						max-width: rem-calc(600);
						left: rem-calc(-120);
					}
				}
				@include breakpoints(tablet-landscape){
					img{
						position: relative;
						bottom: auto;
						left: auto;
						width: 100%;
					}
				}
			}
			h2{
				color: $blue;
			}
			@include breakpoints(tablet-landscape){
				width: auto;
				margin: rem-calc(0 -15);
				padding: rem-calc(0 15);
			}
			&.left{
				width: 50%;
				padding-top: rem-calc(80);
				padding-left: rem-calc(65);
				padding-right: rem-calc(55);
				&:before{
					left: auto;
					right: 100%;
				}
				@include breakpoints(tablet-landscape){
					width: auto;
					padding: rem-calc(50 15 30);
				}
			}
		}
		@include breakpoints(tablet-landscape){
			display: block;
		}
	}
}





.gform_wrapper ul.gform_fields li.gfield{
	&:before{
		display: none;
	}
	&:after{
		display: none;
	}
}


/* Default Form */
.contact-form{
	height: 100%;
	position: relative;
	z-index: 3;
	.gform_wrapper{
		margin-top: 0;	input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]){
			font-family: $proxima_nova;
			font-weight: $light;
			font-style: $italic;
			font-size: rem-calc(18);
			color: $primary_color;
			margin-bottom: 0;
			width: 100%;
			height: rem-calc(60);
			padding: rem-calc(6 18);
			box-shadow: none;
			border-color: $dark_gray;
			border: none;
			@include breakpoints(small){
				font-size: rem-calc(18);
				height: rem-calc(45);
				padding: rem-calc(6 10);
			}
		}
		ul.gform_fields li.gfield{
			margin: rem-calc(0 0 22);
			padding: 0;
		}
		ul{
			li{
				&:before, &:after {
					display: none !important;
				}
			}
		}
		.top_label div.ginput_container{
			margin-top: 0;
		}
		.top_label .gfield_label{
			display: none;
		}
		.gform_heading{
			h3.gform_title{
				font-size: rem-calc(24);
				line-height: rem-calc(30);
				color: $white;
				font-weight: $bold;
				margin-bottom: rem-calc(20);
				color: $white;
				padding: 0;
				margin-bottom: 0;
				margin-top: 0;
			}
		}
		.gform_footer{
			margin-top: 0;
			padding-top: 15px;
			input.button, input[type=submit]{
				background-image: url(../images/button-right-arrow-big-hover.png);
				background-position: 77% center;
				background-repeat: no-repeat;
				background-color: $white;
				color: $orange;
				font-size: rem-calc(24);
				line-height: rem-calc(30);
				height: rem-calc(80); 
				border-color: $orange;
				border-radius: 40px;
				padding: rem-calc(15 84 15 70);
				position: relative;
				&:hover, &:focus{
					background-color: $orange;
					background-image: url(../images/button-right-arrow.png);
					border-color: $white; 
					outline: none;
					color: $white; 
				}
				@include breakpoints(small){
					background-position: 85% center;
					padding: rem-calc(12 50 12 30);
					height: rem-calc(55);
					margin-bottom: 0;
					width: auto;
				}
			}
		}
		/* For Validation */
		li.gfield_error{
			.validation_message{
				color: red;
			} input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), .gform_wrapper li.gfield_error textarea{
				border-color: red;
			}
		}
		div.validation_error{
			display: none;
		}
		.gfield_description{
			font-size: rem-calc(16);
		}
		li.gfield.gfield_error,
		li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning{
			background-color: transparent;
			border: none;
		}
		.field_description_below .gfield_description{
			padding-top: rem-calc(10);
			margin-bottom: rem-calc(10);
		}
		&.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half){
			max-width: 100% !important;
		}
		@include breakpoints(x-small){  input:not([type=radio]):not([type=checkbox]):not([type=image]):not([type=file]) {
			line-height: rem-calc(28);
			min-height: inherit;
			}
		}
		@include breakpoints(small){
			ul.gform_fields li.gfield.gfield_error+li.gfield.gfield_error{
				margin-top: rem-calc(20);
			}
			.gform_footer{
				padding-bottom: 0;
			}
		}
	}
	.gform_confirmation_wrapper  {
		.gform_confirmation_message{
			font-size: rem-calc(20);
			color: #00c500 !important;
			font-weight: $bold;
		}
	}
}


.acf-map{
	position: relative;
	min-height: rem-calc(300);
	padding-top: 45.50%;
	iframe{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}






